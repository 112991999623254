import axios from "axios";
import { Toast } from "antd-mobile";

const myAxios = axios.create({
    timeout: 30000,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Auth-Token': localStorage.getItem("Auth_Token")
    }
})

myAxios.interceptors.request.use((config) => {
    config.headers['Auth-Token'] = localStorage.getItem("Auth_Token");
    return config;
})

myAxios.interceptors.response.use(function (res) {
    console.log(res, '------')
    // alert(JSON.stringify(res.data), '------')
    if ([9998, 4104, 9989, 9, 4105, 4106].includes(res.data.code)) {
        Toast.show(res.data.errorMsg);
        
        return res.data;
    } else if (res.status === 200 && (res.data.code === 0 || res.data.code === 9)) {
        return res.data;
    } else {
        Toast.show(res.data.errorMsg);
        return Promise.reject(res.data);
    }
}, function (err) {
    Toast.show(err);
    return Promise.reject(err);
})

export const get = async (path, param) => {
    const res = await myAxios.get(`/api/${path}`, param, {
    });
    return res;
}

export const post = async (path, param) => {
    const res = await myAxios.post(`/api/${path}`, param, {
    });
    return res;
}