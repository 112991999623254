import React, { useState, useEffect } from 'react';
import MenuBottom from "../common/component/MenuBottom";
import {Toast, Mask, Ellipsis, SpinLoading, Swiper, Dialog, Empty} from 'antd-mobile'
import "../css/sharing.css";
import {useNavigate,useLocation} from "react-router";
import {post, get} from "../common/Http";
import QRCode from 'qrcode.react';
import logoImg from './../../assert/logo-round.png'

function Sharing() {

    const navigateTo = useNavigate();
    const location = useLocation();
    const [bgImg, setBgImg] = useState('');
    const [qrcode, setQrcode] = useState('');
    const [qrcodeSize, setQrcodeSize] = useState(0)
    const [qrcodeImgObj, setQrcodeImgObj] = useState({})
    const [qrcodeStyle, setQrcodeStyle] = useState({})

    useEffect(()=>{
        // 初始化的地方
        getSharing()
        setTimeout(() => {
            let size = document.body.clientWidth * 0.3
            setQrcodeSize(size)
            setQrcodeStyle({
                width: size + 'px',
                height: size + 'px'
            })
        }, 0)
    },[])
    const getSharing = () => {
        get("/u/sharing").then(res => {
            if (res.code === 0) {
                setBgImg(res.data.bgUrl)
                setQrcode(res.data.sharingUrl)
                setTimeout(() => {
                    console.log()
                    let qrcodeWidth = document.getElementById('sharingCodeId').offsetWidth
                    // let qrcodeWidth = document.getElementById('sharingCodeId').offsetWidth
                    console.log(qrcodeWidth, '---', qrcodeWidth/2)
                    let imgWidth = qrcodeWidth*0.25
                    let imgHeight = qrcodeWidth*0.25
                    let xNum = (qrcodeWidth/2) - imgWidth/2
                    console.log('xNum---', xNum)
                    let yNum = (qrcodeWidth/2) - imgHeight/2
                    setQrcodeImgObj({
                        src: logoImg,
                        x: xNum, // 图片在 QR 码中的 x 坐标
                        y: yNum, // 图片在 QR 码中的 y 坐标
                        height: imgHeight, // 图片的高度
                        width: imgWidth, // 图片的宽度
                        excavate: true // 是否开启挖洞，如果为 true，则会在图片上显示为 QR 码的一部分
                    })
                }, 500)
                console.log(res)
            }
        })
    }
    return (
        <>
            <div id="sharingId">
                <img className="sharingBg" src={bgImg} alt="" />
                <div className="qrcodeBox">
                    <div id="codeOulineId" style={qrcodeStyle}>
                        <QRCode
                            id="sharingCodeId"
                            size={qrcodeSize}
                            value={qrcode}
                            fgColor="#000000" // 二维码的颜色
                            imageSettings={qrcodeImgObj}
                            style={{ margin: 'auto' }}
                        />
                    </div>
                    
                </div>
            </div>
        </>
    )
}

export default Sharing;