import React, { useState, useEffect,Component, useRef } from 'react';
import MenuBottom from "../common/component/MenuBottom";
import {Dialog, Toast, Ellipsis, SpinLoading} from 'antd-mobile'
import QRCode from 'qrcode.react';
import "../css/orderDetail.css";
import {useSearchParams} from "react-router-dom";
import {MessageOutline} from "antd-mobile-icons";
import {useNavigate,useLocation} from "react-router";
import {post, get} from "../common/Http";
import VipbgImg from './../../assert/vipbg.jpg'
import novipImg from './../../assert/yvip.jpg'
class CountTimeDown extends Component {
    constructor(props) {
      super(props);
      this.state = {
        day: 0,
        hour: 0,
        minute: 0,
        second: 0
      }
    }
  
    componentDidMount() {
      if (this.props.endTime) {
        let endTime = this.props.endTime.replace(/-/g, "/");
        let startTime = this.props.startTime.replace(/-/g, "/");
        this.countFun(startTime, endTime);
      }
    }
    //组件卸载取消倒计时
    componentWillUnmount() {
      clearInterval(this.timer);
    }
  
    countFun = (startTime, endTime) => {
      let end_time = new Date(endTime).getTime()
      let start_time = new Date(startTime).getTime()
       let sys_second = (end_time - start_time)
      this.timer = setInterval(() => {
        //防止倒计时出现负数
        if (sys_second > 1000) {
          sys_second -= 1000;
          let day = Math.floor((sys_second / 1000 / 3600) / 24);
          let hour = Math.floor((sys_second / 1000 / 3600) % 24);
          let minute = Math.floor((sys_second / 1000 / 60) % 60);
          let second = Math.floor(sys_second / 1000 % 60);
          this.setState({
            day: day,
            hour: hour < 10 ? "0" + hour : hour,
            minute: minute < 10 ? "0" + minute : minute,
            second: second < 10 ? "0" + second : second
          })
        } else {
          clearInterval(this.timer);
        }
      }, 1000);
    };
  
    render() {
      return (
        <span>{this.state.minute}分{this.state.second}秒</span>
      )
    }
  }

function OrderDetail() {
    const navigateTo = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const [vipName, setVipName] = useState('')
    const [amount, setAmount] = useState('0.00')
    const [currentTime, setCurrentTime] = useState(0)
    const [expiredAt, setExpiredAt] = useState(0)
    // 订单编号
    const [orderNo, setOrderNo] = useState('')
    // 订单创建时间
    const [createdAt, setCreatedAt] = useState('')
    // 订单备注
    const [memo, setMemo] = useState('')
    // 支付类型
    const [payType, setPayType] = useState('')
    // 支付信息JSAPI
    const [prepayParam, setPrepayParam] = useState(null)
    // 支付信息二维码
    const [payCode, setPayCode] = useState('')
    // 是否展示二维码
    const [visible, setVisible] = useState(false)
    // 订单状态 PROCESSING：待支付;  FAILED:支付超时
    const [status, setStatus] = useState('')
    let intervalHandle = useRef(false);
    let stopHandle = useRef(false);
    useEffect(()=>{
        // 初始化的地方
        let orderId = location.search.split('?')[1].split('=')[1]
        getData(orderId)
        console.log('location--', location)
        return () => {
            console.log('卸载')
            stopHandle.current = true
        };
    },[])
    // 获取订单详情
    const getData = (id) => {
        get("order/"+id, {}).then(res => {
            if (res.code === 0) {
                console.log(res)
                setVipName(res.data.comName)
                setAmount(res.data.amount)
                setOrderNo(res.data.orderNo)
                setCreatedAt(res.data.createdAt)
                setMemo(res.data.memo)
                setPayType(res.data.orderPayType)
                setStatus(res.data.orderStatus)
                setCurrentTime(res.data.currentTime)
                setExpiredAt(res.data.expiredAt)
                stopHandle.current = false
                if (res.data.orderStatus === 'PROCESSING') {
                    if (!intervalHandle.current){
                        deepLoad(id)
                    }
                    
                }
                if (res.data.orderPayType === 'JSAPI') {
                    setPrepayParam(res.data.paymentExt.prepayParam)
                } else {
                    setPayCode(res.data.paymentExt.qrCode)
                }
            }
        })
        
    }
    const deepLoad = (id) => {
        intervalHandle.current = true
        if (stopHandle.current) {return}
        get("order/"+id, {}).then(res => {
            if (res.code === 0) {
                if (res.data.orderStatus === 'PROCESSING') {
                    setTimeout(() => {
                        deepLoad(id)
                    }, 3000)
                } else {
                    setStatus(res.data.orderStatus)
                    if (res.data.orderStatus === 'FAILED') {
                        Toast.show({
                            content: '订单超时,已取消订单',
                            afterClose: () => {
                                navigateTo("/my");
                            },
                        })
                    } else {
                        Toast.show({
                            content: '订单支付成功',
                            afterClose: () => {
                                navigateTo("/my");
                            },
                        })
                    }
                    
                    
                }
            }
        })
    }
    const onBridgeReady = () => {
        WeixinJSBridge.invoke(
            'getBrandWCPayRequest', {
                "appId": prepayParam.appId, //公众号名称，由商户传入     
                "timeStamp": prepayParam.timeStamp, //时间戳，自1970年以来的秒数     
                "nonceStr": prepayParam.nonceStr, //随机串     
                "package": prepayParam.package,
                "signType": prepayParam.signType, //微信签名方式：     
                "paySign": prepayParam.paySign //微信签名 
            },
            function(res) {
                // 支付成功
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                    // alert('支付成功')
                }
                // 支付过程中用户取消
                if (res.err_msg == "get_brand_wcpay_request:cancel") {
                    // alert('取消支付')
                }
                // 支付失败
                if (res.err_msg == "get_brand_wcpay_request:fail") {
                    // alert('支付失败')
                }
                /**
                 * 其它
                 * 1、请检查预支付会话标识prepay_id是否已失效
                 * 2、请求的appid与下单接口的appid是否一致
                 * */
                if (res.err_msg == "调用支付JSAPI缺少参数：total_fee") {
    
                }
            });
    }
    const payNow = () => {
        if (payType === 'JSAPI') {
            if (typeof WeixinJSBridge == "undefined") {
                if (document.addEventListener) {
                    document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                } else if (document.attachEvent) {
                    document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                    document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                }
            } else {
                onBridgeReady();
            }
        } else {
            setVisible(true)
        }
    }
    // 关闭订单
    const closeOrder = () => {
        
        Dialog.show({
            content: '您确定要关闭订单吗？',
            closeOnAction: true,
            actions: [[
                {
                    key: 'close',
                    text: '取消',
                    onClick: () => {
                    },
                },
                {
                    key: 'confirm',
                    text: '确认',
                    onClick: async () => {
                        stopHandle.current = true
                        intervalHandle.current = true
                        get(`/order/${orderNo}/close`, {}).then(res => {
                            if (res.code === 0) {
                                Toast.show({
                                    content: '订单取消成功',
                                    afterClose: () => {
                                        navigateTo("/my");
                                    },
                                })
                            }
                        })
                    },
                }
              ]],
          })
    }
    
    return (
        <>
        <div className="appMain" id="customVipOrderDetail7D9Us0h">
            <div className="cardTop">
                    <img className="cardTopBg" src={VipbgImg} alt="" />
                    <div className="cardBox">
                        <div className="cardItem">
                            <div className="cardLeft">
                                <div className="title">{vipName}</div>
                            </div>
                            <div className="cardright">
                                <img src={novipImg} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="payBox">
                        <div className="payMain">
                            <div className="payTitle">
                                支付金额
                                {
                                    status === 'PROCESSING' ? (
                                        <span onClick={() => {closeOrder()}}>取消订单</span>
                                    ) : null
                                }
                            </div>
                            <div className="payPrice">
                                <span className="payUnit">¥</span>
                                <span className="pauCount">
                                    {amount}
                                </span>
                            </div>
                            {
                                status === 'FAILED' ? (
                                    <div className="payTime">
                                        订单已取消
                                    </div>

                                ) : ( status === 'PROCESSING' ? (
                                    <div className="payTime">
                                        支付剩余时长：
                                        <span className="timeCount">
                                            <CountTimeDown startTime={currentTime} endTime={expiredAt}></CountTimeDown>
                                        </span>
                                    </div>
                                ) : (
                                    <div className="payTime">
                                        订单已完成
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="orderDetailBox">
                    <div className="listClass">
                            <div className="listItemClass">
                                <div className="copyTitle">订单编号</div>
                                <div className="copyBtnlass">{orderNo}</div>
                            </div>
                            <div className="listItemClass">
                                <div className="copyTitle">订单创建时间</div>
                                <div className="copyBtnlass">{createdAt}</div>
                            </div>
                            <div className="listItemClass">
                                <div className="copyTitle">订单备注</div>
                                <div className="copyBtnlass">{memo}</div>
                            </div>
                        </div>
                    </div>
            </div>
            {
                status === 'PROCESSING' ? (
                    <div className="footerBox">
                        <div className="footer">
                            <div className="footerLeft">
                                <div className="price">
                                    <span>实付：</span>
                                    {amount}
                                </div>
                            </div>
                            <div className="footerRight" onClick={() => {payNow()}}>
                                立即支付
                            </div>
                        </div>
                    </div>
                ) : null
            }
            
            <Dialog
                visible={visible}
                closeOnAction
                title="支付二维码"
                content={
                    (
                        <>
                            <div className="dialogInputClass">
                                <QRCode
                                    id="payCode"
                                    value={payCode}
                                    size={200} // 二维码的大小
                                    fgColor="#000000" // 二维码的颜色
                                    style={{ margin: 'auto' }}
                                />
                            </div>
                        </>
                    )
                }
                actions={[[
                    {
                        key: 'close',
                        text: '取消',
                        onClick: () => {
                            setVisible(false)
                        },
                    },
                    // {
                    //     key: 'confirm',
                    //     text: '下载二维码',
                    //     onClick: async () => {
                    //         changeCanvasToPic()
                    //     },
                    // }
                ]]}
            >
            </Dialog>
            
        </div>
        </>
    )
}

export default OrderDetail;