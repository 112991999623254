import React, { useState, useEffect } from 'react';
import MenuBottom from "../common/component/MenuBottom";
import {Divider, CapsuleTabs, Card, Ellipsis, Grid, Empty} from 'antd-mobile'
import "../css/orderList.css";
import {MessageOutline} from "antd-mobile-icons";
import {useNavigate,useLocation} from "react-router";
import {post, get} from "../common/Http";
import VipImg from './../../assert/yvip.jpg'

function OrderList() {

    const navigateTo = useNavigate();
    const location = useLocation();
    const [data, setData] = useState([])
    const [statusMap, setStatusMap] = useState({
        'PROCESSING': '待支付',
        'SUCCESS': '已完成',
        'FAILED': '已取消',
        'REFUND': '已退款'
    })


    useEffect(()=>{
        // 初始化的地方
        getData('all')
    },[])
    const getData = (type) => {
        get("order/list", {}).then(res => {
            if (res.code === 0) {
                if (type !== 'all') {
                    let filterData = res.data.filter(item => {
                        return item.orderStatus === type
                    })
                    setData(filterData)
                } else {
                    setData(res.data)
                }
            }
        })
    }
    const tabChange = (e) => {
        console.log(e)
        getData(e)
    }
    const ToDetail = (orderNo) => {
        navigateTo(`/orderDetail?orderId=${orderNo}`);
    }

    return (
        <>
            <div className="listBox" id="orderListBox1G0">
                <div className="listTitle">
                    <CapsuleTabs defaultActiveKey='all' onChange={(e) => {
                        tabChange(e)
                    }}>
                        <CapsuleTabs.Tab title='全部' key='all'></CapsuleTabs.Tab>
                        <CapsuleTabs.Tab title='待支付' key='PROCESSING'></CapsuleTabs.Tab>
                        <CapsuleTabs.Tab title='已完成' key='SUCCESS'></CapsuleTabs.Tab>
                        <CapsuleTabs.Tab title='已取消' key='FAILED'></CapsuleTabs.Tab>
                    </CapsuleTabs>
                </div>
                <div className="listMain">
                    {
                        data.length > 0
                        ?
                        data.map(item => {
                            return (
                                <div className={"card-cover"} key={item.id}>
                                    <Card className={"adm-card-body"} onClick={()=>ToDetail(item.orderNo)}>
                                        <div className={"content"}>
                                            <Grid columns={5} gap={8}>
                                                <Grid.Item span={1} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                    <div className={"left-card-title_img_box"}>
                                                        <img src={VipImg} alt="" width={50}/>
                                                    </div>
                                                </Grid.Item>
                                                <Grid.Item span={3}>
                                                    <div className={"right-card-title"}>
                                                        <h3>
                                                            {item.comName}
                                                        </h3>
                                                        {/* <div>
                                                            <Ellipsis direction='end' rows={2} content={'nikaniasdnjs'} />
                                                        </div> */}
                                                    </div>
                                                </Grid.Item>
                                                <Grid.Item span={1}>
                                                    <div className={"right-card-title statusName"}>
                                                        {statusMap[item.orderStatus]}
                                                    </div>
                                                </Grid.Item>
                                            </Grid>
                                
                                        </div>
                                        <Divider />
                                        <div className={"company-name"}>
                                            <div className="itemFooterL">
                                                <span>¥</span>
                                                {item.amount}
                                            </div>
                                            <div className="itemFooterR">
                                                下单时间：{item.createdAt}
                                            </div>
                                        </div>
                                    </Card>
                            </div>
                            )
                        })
                        :
                        <Empty description='暂无数据' style={{paddingTop: '20rem'}}/>
                    }
                </div>
            </div>
            <div className={"bottom-tab"}>
                <MenuBottom></MenuBottom>
            </div>
        </>
    )
}

export default OrderList;