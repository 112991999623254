import React, { useState, useEffect } from 'react';
import MenuBottom from "../common/component/MenuBottom";
import {Divider, CapsuleTabs, Card, Ellipsis, Grid, Button, Toast, Empty} from 'antd-mobile'
import "../css/orderList.css";
import {MessageOutline} from "antd-mobile-icons";
import {useNavigate,useLocation} from "react-router";
import {post, get} from "../common/Http";
import VipImg from './../../assert/yvip.jpg'

function MyCard() {

    const navigateTo = useNavigate();
    const location = useLocation();
    const [data, setData] = useState([])
    const [activeKey, setActiveKey] = useState('all')
    const [statusMap, setStatusMap] = useState({
        'ACTIVE': '已生效',
        'INACTIVE': '待激活',
    })


    useEffect(()=>{
        // 初始化的地方
        getData('all')
    },[])
    const getData = (type) => {
        get("/u/packs", {}).then(res => {
            if (res.code === 0) {
                if (type !== 'all') {
                    let filterData = res.data.filter(item => {
                        return item.vipStatus === type
                    })
                    setData(filterData)
                } else {
                    setData(res.data)
                }
            }
        })
    }
    const tabChange = (e) => {
        setActiveKey(e)
        getData(e)
    }
    const activeCard = (id) => {
        get('/u/active/'+id).then(res => {
            if (res.code === 0) {
                Toast.show({
                    icon: 'success',
                    content: '激活成功',
                    afterClose: () => {
                        getData(activeKey)
                    },
                })
            }
            
        })
    }

    return (
        <>
            <div className="listBox" id="orderListBox1G0">
                <div className="listTitle">
                    <CapsuleTabs defaultActiveKey='all' onChange={(e) => {
                        tabChange(e)
                    }}>
                        <CapsuleTabs.Tab title='全部' key='all'></CapsuleTabs.Tab>
                        <CapsuleTabs.Tab title='已生效' key='ACTIVE'></CapsuleTabs.Tab>
                        <CapsuleTabs.Tab title='待激活' key='INACTIVE'></CapsuleTabs.Tab>
                    </CapsuleTabs>
                </div>
                <div className="listMain">
                    {
                        data.length > 0 
                        ?
                        data.map(item => {
                            return (
                                <div className={"card-cover"} key={item.id}>
                                    <Card className={"adm-card-body"}>
                                        <div className={"content"}>
                                            <Grid columns={5} gap={8}>
                                                <Grid.Item span={1} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                    <div className={"left-card-title_img_box"}>
                                                        <img src={VipImg} alt="" width={50}/>
                                                    </div>
                                                </Grid.Item>
                                                <Grid.Item span={3}>
                                                    <div className={"right-card-title"}>
                                                        <h3>
                                                            {item.vipInfoName}
                                                        </h3>
                                                        {/* <div>
                                                            <Ellipsis direction='end' rows={2} content={'nikaniasdnjs'} />
                                                        </div> */}
                                                    </div>
                                                </Grid.Item>
                                                <Grid.Item span={1}>
                                                    <div className={"right-card-title statusName"}>
                                                    {
                                                        item.vipStatus === 'ACTIVE' ? statusMap[item.vipStatus] : (
                                                            <Button color='primary' size="small" fill='outline' onClick={() => {
                                                                activeCard(item.id)
                                                            }}>
                                                                激活
                                                            </Button>
                                                        )
                                                    }
                                                    </div>
                                                </Grid.Item>
                                            </Grid>
                                
                                        </div>
                                        <Divider />
                                        <div className={"company-name"} style={{justifyContent: 'right'}}>
                                            <div className="itemFooterR">
                                                过期时间：{item.expiredAt}
                                            </div>
                                        </div>
                                    </Card>
                            </div>
                            )
                        })
                        :
                        <Empty description='暂无数据' style={{paddingTop: '20rem'}}/>
                    }
                </div>
            </div>
            <div className={"bottom-tab"}>
                <MenuBottom></MenuBottom>
            </div>
        </>
    )
}

export default MyCard;