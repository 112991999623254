import React, {useState, useEffect} from 'react';
import {NavBar, Toast, Grid, Ellipsis} from 'antd-mobile'
import "../css/details.css";
import {RightOutline} from "antd-mobile-icons";
import {useLocation, useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";
import {get} from "../common/Http";
import MenuBottom from "../common/component/MenuBottom";

function Details() {

    const navigateTo = useNavigate();

    const location = useLocation();
    const [searchParams] = useSearchParams();

    const [appDetail, setAppDetail] = useState({});

    const back = () => {

        navigateTo(-1);

    }

    const getUrl = async () => {

        let appCode = searchParams.get("appCode");
        let isShared = searchParams.get("isShared");

        try {
            if (!isShared) {
                isShared = false;
            }

            const res = await get("app/url/" + appCode + "/" + isShared);
            if (res.code === 0) {
                // setAppDetail(res.data);
                window.location.href = res.data;
            } else if (res.code === 9998) {
                navigateTo("/login" ,{ state: { from: location } });
            } else {
                Toast.show(res.errorMsg)
            }

        } catch (e) {
            console.log(e);
        }

    }

    const getHpUrl = async () => {
        let appCode = searchParams.get("appCode");
        try {

            const res = await get("app/hpUrl/" + appCode);
            if (res.code === 0) {
                // setAppDetail(res.data);
                window.location.href = res.data;
            } else if (res.code === 9998) {
                navigateTo("/login" ,{ state: { from: location } });
            } else {
                Toast.show(res.errorMsg)
            }

        } catch (e) {
            console.log(e);
        }

    }

    useEffect(() => {

        const getData = async () => {

            let appCode = searchParams.get("appCode");
            try {

                const res = await get("app/detail/" + appCode);
                if (res.code === 0) {
                    setAppDetail(res.data);
                    // setHomePage(res.data.homepageUrl);
                } else if (res.code === 9998) {
                    navigateTo("/login");
                } else {
                    Toast.show(res.errorMsg)
                }

            } catch (e) {
                console.log(e);
            }


        }

        getData();

    }, [])

    return (
        <>
            <NavBar backArrow={false} onBack={back}
                    style={{backgroundColor: "#EFEFEF", maxWidth: "650px", margin: "0 auto"}}>
                知新AI
            </NavBar>
            <div className={"detail-container"}>
                <div className={"detail-body"}>
                    <div className="GridClass">
                        <div className="leftBoxGrid">
                            <div className="GridItemImg">
                                <img src={appDetail.icon} alt="" />
                            </div>
                            <div className="GridItemName">
                                {appDetail["appName"]}
                            </div>
                        </div>
                        <div className="GridItemInter">
                            <a onClick={getUrl}>
                                进入应用 
                                <RightOutline  style={{marginLeft: '0.8rem'}}/>
                            </a> 
                                {/* <img src={imgIcon2} alt="" width={26}/></a> */}
                        </div>
                    </div>
                    <div className={"detail-company-content"}>
                        <div className={"detail-company-flex"}>
                            <Grid columns={4} gap={0} className={""}>
                                <Grid.Item span={3}>
                                    <div className={"detail-company-name"}>
                                        <p style={{fontWeight: 'bold'}}>{appDetail["company"] || ''} </p>
                                    </div>
                                </Grid.Item>
                                <Grid.Item span={1}>
                                    <div className={"detail-third"}>
                                        {appDetail["hpExist"] ? <div className={"detail-company-link"}>
                                            <a onClick={getHpUrl}>
                                                主页 
                                                <RightOutline style={{marginLeft: '0.8rem'}}/>
                                                {/* <img
                                                src={imgIcon3} alt="" width={8}/> */}
                                            </a>
                                        </div> : null}
                                    </div>
                                </Grid.Item>
                            </Grid>
                        </div>
                        <h2>介绍</h2>
                        <div className={"detail-company-p"}
                             dangerouslySetInnerHTML={{__html: appDetail["appDesc"]}}></div>
                    </div>

                </div>
            </div>
            <div className={"bottom-tab"}>
                <MenuBottom></MenuBottom>
            </div>
        </>
    )
}

export default Details;