import {Route, Routes} from "react-router-dom";
import LikeList from "./components/pages/LikeList";
import SignUp from "./components/pages/SignUp";
import Login from "./components/pages/Login";
import Find from "./components/pages/Find";
import Details from "./components/pages/Details";
import My from "./components/pages/My";
import MyApp from "./components/pages/MyApp";
import ResetPassword from "./components/pages/ResetPassword";
import CreateOrder from './components/pages/CreateOrder';
import VipList from './components/pages/VipList';
import OrderDetail from './components/pages/orderDetail';
import OrderList from './components/pages/orderList';
import ActivePage from './components/pages/ActivePage';
import MyCard from './components/pages/MyCard';
import Sharing from './components/pages/Sharing'



function AllRoutes() {
    return (
        <Routes>
            <Route index element={<Find />}></Route>
            <Route path={"/signup"} element={<SignUp />}></Route>
            <Route path={"/login"} element={<Login />}></Route>
            <Route path={"/find"} exact element={<Find />}></Route>
            <Route path={"/like"} element={<LikeList />}></Route>
            <Route path={"/details"} element={<Details />}></Route>
            <Route path={"/my"} element={<My />}></Route>
            <Route path={"/my-app"} element={<MyApp />}></Route>
            <Route path={"/reset"} element={<ResetPassword />}></Route>
            <Route path={"/createOrder"} element={<CreateOrder />}></Route>
            <Route path={"/vipList"} element={<VipList />}></Route>
            <Route path={"/orderDetail"} element={<OrderDetail />}></Route>
            <Route path={"/orderList"} element={<OrderList />}></Route>
            <Route path={"/activePage"} element={<ActivePage />}></Route>
            <Route path={"/myCard"} element={<MyCard />}></Route>
            <Route path={'/sharing'} element={<Sharing/>}></Route>
        </Routes>
    );
}

export default AllRoutes;