import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Toast, Divider, Space } from 'antd-mobile';
import "../css/login.css"
import logo from "../../assert/loginLogo.png";
import { useLocation, useNavigate } from "react-router";
import { get, post } from "../common/Http";
import { EyeInvisibleOutline, EyeOutline } from "antd-mobile-icons";
import { ChatCheckOutline, FillinOutline, MessageOutline,UserCircleOutline } from 'antd-mobile-icons'



function Login() {

    const navigateTo = useNavigate();

    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [oneCode, setOneCode] = useState("");
    const [loginType, setLoginType] = useState("PASSWORD");
    const [codeSendStep, setCodeSendStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [sendLoading, setSendLoading] = useState(false);
    const [eyeFlag, setEyeFlag] = useState(true);
    const [content, setContent] = useState("");
    const [contentUrl, setContentUrl] = useState("");
    const location = useLocation();

    const onFinish = (value) => {
        console.log(value)
    }

    const changeLoginType = (type) => {
        setLoginType(type);
    }

    const handleSendCode = async () => {

        if (!phoneNumber) {
            Toast.show("请输入手机号");
            return
        }

        const param = {
            username: phoneNumber
        }

        try {
            setSendLoading(true);
            const res = await post("u/sms/send", param);
            if (res.code === 0) {
                setCodeSendStep(2);
                setSendLoading(false);
                Toast.show({
                    content: "发送成功！",
                    duration: 2000,
                    afterClose: () => {

                    }
                })
            } else {
                Toast.show(res.errorMsg)
                setLoading(true);
            }

        } catch (e) {
            console.log(e);
            setLoading(true);
        }

    }

    // 定义检查是否为微信浏览器的函数
    const isWeChatBrowser = () => {
        // 获取用户浏览器的userAgent字符串
        const userAgent = navigator.userAgent.toLowerCase();
        // 检查是否包含'micromessenger'（不区分大小写）
        return userAgent.includes('micromessenger');
    };

    const loginByWechat = async () => {

        if (!isWeChatBrowser()) {
            Toast.show({
                content: "请使用微信打开页面后重试",
                duration: 2000,
                afterClose: () => {
                }
            })
            return;
        }

        setLoading(true);
        // http://172.20.10.3:3000
        window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx72aced5ed21f73a8&redirect_uri=https://chat.findnew.cn/login&forcePopup=true&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";

    }


    const login = async () => {

        if (!phoneNumber) {
            Toast.show("请输入手机号");
            return
        }
        if (loginType === "PASSWORD" && !password) {
            Toast.show("请输入密码");
            return
        }

        if (loginType === "VERIFY_CODE" && !oneCode) {
            Toast.show("请输入验证码");
            return
        }

        let param = {
            username: phoneNumber,
            loginType: loginType
        }

        if (loginType === "PASSWORD") {
            param.password = password;
        } else {
            param.verifyCode = oneCode;
        }

        localStorage.setItem("number_user", phoneNumber);

        try {
            setLoading(true);
            const res = await post("u/login", param);
            if (res.code === 0) {
                if (loginType === "PASSWORD") {
                    localStorage.setItem("auth_pwd", password);
                }
                localStorage.setItem("Auth_Token", res.data);

                Toast.show({
                    content: "登录成功！",
                    duration: 2000,
                    afterClose: () => {
                        setLoading(true);
                        // navigateTo("/find");
                        navigateTo(location.state?.from || '/find');
                    }
                })
            } else {
                Toast.show({
                    content: res.errorMsg,
                    duration: 2000,
                    afterClose: () => {
                        setLoading(false);
                        navigateTo("/signup");
                    }
                })
                setLoading(false);
            }

        } catch (e) {
            console.log(e);
            setLoading(false);
        }

    }

    const changeEye = () => {
        setEyeFlag(!eyeFlag);
    }


    function getCodeFromURL() {
        const queryParams = new URLSearchParams(window.location.search);
        return queryParams.get('code');
    }


    useEffect(() => {

        const code = getCodeFromURL();

        const getTokenByWechat = async () => {
            const res = await get("u/login/wechat/" + code);
            if (res.code === 0) {
                localStorage.setItem("Auth_Token", res.data);

                Toast.show({
                    content: "登录成功！",
                    duration: 2000,
                    afterClose: () => {
                        setLoading(false);
                        navigateTo(location.state?.from || '/find');
                    }
                })
            }
        }

        if(code){
            setLoading(true);
            getTokenByWechat();
        }
 

        const getData = async () => {

            try {
                const res = await get("u/protocol");
                if (res.code === 0) {
                    setContent(res.data.content);
                    setContentUrl(res.data.fileUrl);
                } else {
                    Toast.show(res.errorMsg)
                }

            } catch (e) {
                console.log(e);
            }
        }
        getData();

        if (localStorage.getItem("auth_pwd")) {
            setPassword(localStorage.getItem("auth_pwd"));
            setPhoneNumber(localStorage.getItem("number_user"));
        }

        if (localStorage.getItem("number_user")) {
            setPhoneNumber(localStorage.getItem("number_user"));
        }

    }, [])


    return (
        <>
            <div className={"login-container"}>
                <div className={"up-box"}>
                    <div className={"title"}>您好<br />欢迎使用知新AI</div>
                    <div className={"title-logo"}>
                        <img src={logo} width={80} height={30} alt="" />
                    </div>
                </div>
                <div className={"form-area"}>
                    <Form
                        name='form'
                        onFinish={onFinish}>
                        <Form.Item label='手机号'>
                            <Input placeholder='请输入您的手机号' value={phoneNumber} onChange={(val) => {
                                setPhoneNumber(val)
                            }} />
                        </Form.Item>

                        {loginType === "PASSWORD" ? <Form.Item label='密码' className={"pwd-eye"}>
                            {eyeFlag ? <a href="#" className={"pwd-eye-icon"} onClick={changeEye}><EyeInvisibleOutline
                                fontSize={36} /></a> :
                                <a href="#" className={"pwd-eye-icon"} onClick={changeEye}><EyeOutline
                                    fontSize={36} /></a>}
                            <Input type={eyeFlag ? "password" : "text"} placeholder='请输入密码' value={password}
                                onChange={(val) => {
                                    setPassword(val)
                                }} />
                        </Form.Item>
                            : <Form.Item label='验证码'>
                                <Input placeholder='请输入验证码' maxLength={6} onChange={(val) => {
                                    setOneCode(val)
                                }} clearable />
                            </Form.Item>}
                    </Form>
                </div>
                <div className={"small-form"}>
                    <div>
                        <a href="/reset">忘记密码？</a>
                    </div>
                    <div>
                        {loginType === "VERIFY_CODE" ?
                            <a href="#" onClick={() => changeLoginType("PASSWORD")}>密码登录</a> :
                            <a href="#" onClick={() => changeLoginType("VERIFY_CODE")}>验证码登录</a>}
                    </div>
                </div>
                <div className={"foot-text"}>
                    <a href={contentUrl} target={"_blank"}>{content}</a>
                </div>


                
                <div className={"btn-determine-sign"}>
                    {loginType === "VERIFY_CODE" && codeSendStep === 1 &&
                        <Button block color='primary' size='large' shape='rounded' loading={sendLoading}
                            onClick={handleSendCode}>
                                   <Space>
                                <MessageOutline />
                                <span>发送验证码</span>
                            </Space>
                            
                        </Button>}
                    {loginType === "VERIFY_CODE" && codeSendStep === 2 &&

                        <Button block color='primary' size='large' shape='rounded' loading={loading} onClick={login}>
                            <Space>
                                <MessageOutline />
                                <span>验证码登录</span>
                            </Space>
                        </Button>}
                    {loginType === "PASSWORD" &&

                        <Button block color='primary' size='large' shape='rounded' loading={loading} onClick={login}>
                            <Space>
                            <UserCircleOutline />
                                <span>账号登录</span>
                            </Space>
                        </Button>}

                </div>
                <div className={"btn-determine-sign"}>
                    <Button block color='success' size='large' shape='rounded' loading={loading} onClick={loginByWechat}>
                        <Space>
                            {/* <WechatIcon /> */}
                            <ChatCheckOutline />
                            <span>微信登录</span>
                        </Space>
                    </Button>
                </div>
                <div className={"btn-determine-sign"}>
                    <Button block fill='outline' color="primary" size='large' shape='rounded' loading={loading}
                        onClick={() => {
                            navigateTo("/signup")
                        }}>
                           <Space>
                                <FillinOutline />
                                <span>账号注册</span>
                            </Space>
                    </Button>
                </div>






                <div className={"foot-text"}>
                </div>
            </div>
        </>
    )
}

export default Login;