import AllRoutes from "./AllRoutes";
import './App.css';

function App () {
  return (
    <div className="App">
      <AllRoutes />
    </div>
  );
}

export default App;
