import React, { useState, useEffect } from 'react';
import MenuBottom from "../common/component/MenuBottom";
import {Toast, Mask, Ellipsis, SpinLoading, Swiper, Dialog, Empty} from 'antd-mobile'
import "../css/vipList.css";
import {RightOutline} from "antd-mobile-icons";
import {useNavigate,useLocation} from "react-router";
import {post, get} from "../common/Http";
import VipbgImg from './../../assert/vipbg.jpg'
import novipImg from './../../assert/noVip.jpg'
import yvipImg from './../../assert/yvip.jpg'
import cardIcon from './../../assert/cardIcon.svg'

function VipList() {

    const navigateTo = useNavigate();
    const location = useLocation();
    const [list, setList] = useState([]);
    const [vipInfo, setVipInfo] = useState(null);
    const [activeItem, setActiveItem] = useState({});
    const [periodTypeObj, setPeriodTypeObj] = useState({
        DAY: '天'
    })
    const [tagCurrent, setTagCurrent] = useState('NORMAL')
    const [visible, setVisible] = useState(false)
    const [cardCount, setCardCount] = useState(0)

    useEffect(()=>{
        getMyVip()
        // 初始化的地方
        getData('NORMAL')
        getActiveList()
    },[])
    // 获取会员列表
    const getData = async (type) => {
        const param = {
            vipType: type
        }
        const res = await post("order/vip", param);
        if(res.code === 0) {
            setList(res.data);
            setActiveItem(res.data.length > 0 ? res.data[0] : {})
        } else if(res.code === 9998)  {
            Toast.show(res.errorMsg);
            navigateTo("/login");
        } else {
            Toast.show({
                content: res.errorMsg,
                duration: 2000,
            })
        }
    }
    // 获取当前登录人的VIP
    const getMyVip = () => {
        get("u/mine").then(res => {
            if (res.code === 0) {
                if (res.data) {
                    if (res.data.vipInfo && res.data.vipInfo.length > 0) {
                        setVipInfo(res.data.vipInfo)
                    } else {
                        setVipInfo(null)
                    }
                }
            } else if (res.code === 9998) {
                Toast.show(res.errorMsg);
                navigateTo("/login");
            } else {
                Toast.show({
                    content: res.errorMsg,
                    duration: 2000,
                })
            }
        })
    }
    const itemChange = (item) => {
        setActiveItem(item)
    }

    const onFinish=() => {
        if (Object.keys(activeItem).length === 0) {
            Toast.show('请选择要购买的会员')
            return
        }
        setVisible(true)
        createdOrder()
    }
    const getActiveList = () => {
        get("/u/packs").then(res => {
            if (res.code === 0) {
                setCardCount(res.data.length)
            }
        })
    }
    const createdOrder = async() => {
        
        const param = {
            "comId": activeItem.id,
            "quantity": 1,
            "memo": activeItem.vipName
        }
        const res = await post("order/payment", param);
        console.log(res, '--res')
        setVisible(false)
        if (res.code === 0) {
            navigateTo(`/orderDetail?orderId=${res.data.orderNo}`);
        } else if (res.code === 9998) {
            navigateTo("/login");
        } else if (res.code === 4105) {
            Dialog.show({
                content: '您有订单未处理，请处理后再进行下单',
                closeOnAction: true,
                actions: [[
                    {
                        key: 'close',
                        text: '取消',
                        onClick: () => {
                        },
                    },
                    {
                        key: 'confirm',
                        text: '前去处理',
                        onClick: async () => {
                            navigateTo(`/orderDetail?orderId=${res.errorMsg}`);
                        },
                    }
                  ]],
              })
        } else {
            Toast.show(res.errorMsg)
        }
    }
    return (
        <>
        <div id="vipListTopId">
            <div className="cardTop" >
                <img className="cardTopBg" src={VipbgImg} alt="" />
                <div className="cardTitleLine">
                    <div className="cardTitleBox">
                        <div className="cardLineLeft">
                            <img src={cardIcon} alt="" />
                            <span className="cardText">
                                我的卡包
                            </span>
                        </div>
                        <div className="cardLineRight" onClick={() => {
                            navigateTo("/myCard");
                        }}>
                            <span className="cardCount">{cardCount}</span>
                            <span className="cardCountIcon">
                                <RightOutline/>
                            </span>
                        </div>
                    </div>
                    {/* <div className="cardTipBox">
                        什么是高级卡？高级卡如何计费？
                    </div> */}
                </div>
                {
                    !vipInfo ? (
                        <div className="cardBox">
                            <div className="cardItem">
                                <div className="cardLeft">
                                    <div className="title">未开通会员</div>
                                    <div className="tip">开通标准卡，畅享丰富特权</div>
                                </div>
                                <div className="cardright">
                                    <img src={novipImg} alt="" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Swiper>
                            {
                                vipInfo.map((item, index) => {
                                    return (
                                        <Swiper.Item key={index}>
                                            <div className="cardItem cardItemBg">
                                                <div className="cardLeft">
                                                    <div className="title">{item.vipInfoName}</div>
                                                    <div className="tip expireDatTip">到期时间：{item.expiredAt}</div>
                                                </div>
                                                
                                                <div className="cardright">
                                                    <img src={yvipImg} alt="" />
                                                </div>
                                            </div>
                                      </Swiper.Item> 
                                    )
                                })
                            }
                        </Swiper>
                    )
                }
            </div>
            
            <div className="tagBox">
                <div className="tagItem" onClick={() => {
                        setTagCurrent('NORMAL')
                        getData('NORMAL')
                    }}>
                    <span className={tagCurrent === 'NORMAL' ? 'activeSpan' : ''}>
                        普通会员
                    </span>
                </div>
                <div className="tagItem" onClick={() => {
                    setTagCurrent('MERCHANT')
                    getData('MERCHANT')
                    // Toast.show({
                    //     content: '暂未开放'
                    // })
                }}>
                    <span className={tagCurrent === 'MERCHANT' ? 'activeSpan' : ''}>
                        商户会员
                    </span>
                    
                </div>
            </div>
            <div className="vipList">
                <div>
                {
                        list.map((item, index) => {
                            return (
                                <div key={index} className={activeItem.id === item.id ? 'activeItem' : 'vipItem'} onClick={() => {
                                    itemChange(item)
                                }}>
                                    <div className="title">
                                        <Ellipsis direction='end' content={item.vipName} />
                                    </div>
                                    <div className="price">¥{item.price}</div>
                                    <div className="fPrice"><span className="priceIcon">¥</span>{item.finalPrice}</div>
                                    <div className="day">{item.period}{periodTypeObj[item.periodType]}</div>
                                </div>
                            )
                        })
                    }
                </div>
                   
            </div>
            
            <div className="vipDetailBox">
                    <div className="detailTitleBox">
                        <div className="titleText">
                            会员权益
                        </div>
                        <div className="tipText">
                            开通可畅享多项权益
                        </div>
                    </div>
                    <div className="detailImg">
                        {
                            activeItem.vipDescImg ?
                            <img src={activeItem.vipDescImg} alt="" /> :
                            <Empty description='暂无数据' />
                        }
                        
                    </div>
            </div>
            <div className="footerBox">
                <div className="footer">
                    <div className="footerLeft">
                        <div className="title">开通{activeItem.vipName} <span style={{paddingLeft: '1rem'}}>{activeItem.period}{periodTypeObj[activeItem.periodType]}</span></div>
                        <div className="price">
                            <span>实付：</span>
                            {activeItem.finalPrice}
                        </div>
                    </div>
                    <div className="footerRight" onClick={() => {onFinish()}}>
                        立即开通
                    </div>
                </div>
            </div>
            <Mask visible={visible}>
                <div className="loadClass">
                    
                    <SpinLoading color="#fff" style={{ '--size': '32px' }} />
                    <div className="loadTitle">
                        正在下单...
                    </div>
                    
                </div>
            </Mask>
        </div>
            
        </>
    )
}

export default VipList;