import React, { useEffect, useState, Component,  } from "react";
import {useSearchParams} from "react-router-dom";
import { Toast } from 'antd-mobile'
import { FillinOutline } from 'antd-mobile-icons'
import "../css/activePage.css";
import { useNavigate } from "react-router";
import dayjs from 'dayjs'
import { get, post } from "../common/Http";
import logoTwoImg from "../../assert/activeLogo.svg";
import activeTitleImg from '../../assert/yvip.jpg'
import QRCode from 'qrcode.react';
import FooterLogo from '../../assert/footerLogo.svg'
import PdSVG from '../../assert/pd.svg'
class CountTimeDown extends Component {
    constructor(props) {
      super(props);
      this.state = {
        day: 0,
        hour: 0,
        minute: 0,
        second: 0,
        activeStatus: 0, // 活动状态0: 未开始；1：在活动时间范围内；2活动已经结束    
    }
    }
  
    componentDidMount() {
      if (this.props.endTime) {
        console.log(this.props.periodType)
        if (this.props.periodType === "ONCE") {
            let endTime = this.props.endTime.replace(/-/g, "/");
            let startTime = this.props.startTime.replace(/-/g, "/");
            let currentTime = this.props.currentTime.replace(/-/g, '/')
            console.log(currentTime ,'----', startTime, 'vvv--', new Date(currentTime).getTime() > new Date(endTime).getTime())
            if (new Date(currentTime).getTime() < new Date(startTime).getTime()) {
                this.setState({
                    activeStatus: 0
                },()=>{
                    this.countFun(currentTime, startTime);
                })
                this.props.getActiveStatus(0)
                
            } else if (new Date(currentTime).getTime() > new Date(endTime).getTime()) {
                this.setState({
                    activeStatus: 2
                })
                this.props.getActiveStatus(2)
            } else {
                this.setState({
                    activeStatus: 1
                }, () => {
                    this.countFun(currentTime, endTime);
                })
                this.props.getActiveStatus(1)
                
            }
        } else {
            let endTime = '2021/06/06 ' + dayjs(this.props.endTime).format('HH:mm:ss')
            let startTime = '2021/06/06 ' + dayjs(this.props.startTime).format('HH:mm:ss')
            let currentTime = '2021/06/06 ' + dayjs(this.props.currentTime).format('HH:mm:ss')
            if (new Date(currentTime).getTime() < new Date(startTime).getTime()) {
                this.setState({
                    activeStatus: 0
                  }, () => {
                    this.countFun(currentTime, startTime);
                  })
                  this.props.getActiveStatus(0)
            } else if (new Date(currentTime).getTime() > new Date(endTime).getTime()) {
                this.setState({
                    activeStatus: 2
                })
                this.props.getActiveStatus(2)
            } else {
                this.setState({
                    activeStatus: 1
                }, () => {
                    this.countFun(currentTime, endTime);
                })
                this.props.getActiveStatus(1)
                
            }
        }
        
      }
    }
    //组件卸载取消倒计时
    componentWillUnmount() {
      clearInterval(this.timer);
    }
  
    countFun = (startTime, endTime) => {
        let end_time = new Date(endTime).getTime()
        let start_time = new Date(startTime).getTime()
        let sys_second = (end_time - start_time)
        if (this.timer) return
        this.timer = setInterval(() => {
            console.log('sys_secondsys_second---', sys_second)
            //防止倒计时出现负数
            if (sys_second > 1000) {
                sys_second -= 1000;
                let day = Math.floor((sys_second / 1000 / 3600) / 24);
                let hour = Math.floor((sys_second / 1000 / 3600) % 24);
                let minute = Math.floor((sys_second / 1000 / 60) % 60);
                let second = Math.floor(sys_second / 1000 % 60);
                this.setState({
                    day: day,
                    hour: hour < 10 ? "0" + hour : hour,
                    minute: minute < 10 ? "0" + minute : minute,
                    second: second < 10 ? "0" + second : second
                })
            } else {
                clearInterval(this.timer);
                this.timer = null
                if (this.state.activeStatus === 0) { // 开始时间倒计时结束直接进行结束倒计时
                    let endTime = this.props.endTime.replace(/-/g, "/");
                    let startTime = this.props.startTime.replace(/-/g, "/");
                    this.setState({
                        activeStatus: 1
                    }, () => {
                        let end_time = new Date(endTime).getTime()
                        let start_time = new Date(startTime).getTime()
                        let sys_second = (end_time - start_time)
                        let day = Math.floor((sys_second / 1000 / 3600) / 24);
                        let hour = Math.floor((sys_second / 1000 / 3600) % 24);
                        let minute = Math.floor((sys_second / 1000 / 60) % 60);
                        let second = Math.floor(sys_second / 1000 % 60);
                        this.setState({
                            day: day,
                            hour: hour < 10 ? "0" + hour : hour,
                            minute: minute < 10 ? "0" + minute : minute,
                            second: second < 10 ? "0" + second : second
                        })
                        this.countFun(startTime, endTime)
                    })
                    this.props.getActiveStatus(1)
                    
                } else { // 结束倒计时结束后说明活动已结束了
                    this.setState({
                        activeStatus: 2
                    })
                    this.props.getActiveStatus(2)
                }
            }
        }, 1000);
    };
  
    render() {
        if (this.props.periodType === "ONCE") {
            return (
                <div className="activeTimeLine">
                    <div className="pd">
                        <img src={PdSVG} alt="" />
                    </div>
                    {
                        this.state.activeStatus === 0 ? (
                            <div className="flexBox">
                                <div className="timeItem">{this.state.day}</div>
                                <div className="timeText">天</div>
                                <div className="timeItem">{this.state.hour}</div>
                                <div className="timeText">小时</div>
                                <div className="timeItem">{this.state.minute}</div>
                                <div className="timeText">分</div>
                                <div className="timeItem">{this.state.second}</div>
                                <div className="timeText">秒</div>
                                <div className="timeText">后开始</div>
                            </div>
                        ) : (
                            this.state.activeStatus === 2 ? (
                                <div className="activePageOver">活动已结束</div>
                            ) : (
                                <div className="flexBox">
                                    <div className="timeItem">{this.state.day}</div>
                                    <div className="timeText">天</div>
                                    <div className="timeItem">{this.state.hour}</div>
                                    <div className="timeText">小时</div>
                                    <div className="timeItem">{this.state.minute}</div>
                                    <div className="timeText">分</div>
                                    <div className="timeItem">{this.state.second}</div>
                                    <div className="timeText">秒</div>
                                    <div className="timeText">后结束</div>
                                </div>
                            )
                        )
                    }
                    
                </div>
            )
        } else {
            return (
                <div className="activeTimeLine">
                    <div className="pd">
                        <img src={PdSVG} alt="" />
                    </div>
                    {
                        this.state.activeStatus === 0 ? (
                            <div className="flexBox">
                                <div className="timeItem">{this.state.hour}</div>
                                <div className="timeText">小时</div>
                                <div className="timeItem">{this.state.minute}</div>
                                <div className="timeText">分</div>
                                <div className="timeItem">{this.state.second}</div>
                                <div className="timeText">秒</div>
                                <div className="timeText">后开始</div>
                            </div>
                        ) : (
                            this.state.activeStatus === 2 ? (
                                <div className="activePageOver">活动已结束</div>
                            ) : (
                                <div className="flexBox">
                                    <div className="timeItem">{this.state.hour}</div>
                                    <div className="timeText">小时</div>
                                    <div className="timeItem">{this.state.minute}</div>
                                    <div className="timeText">分</div>
                                    <div className="timeItem">{this.state.second}</div>
                                    <div className="timeText">秒</div>
                                    <div className="timeText">后结束</div>
                                </div>
                            )
                        )
                    }
                </div>
            )
        }
    }
  }


function ActivePage() {

    const navigateTo = useNavigate();
    const [searchParams] = useSearchParams();
    const activeCode = searchParams.get("code")
    const [title, setTitle] = useState('XX大学浙江校友会AI主题季')
    const [periodType, setPeriodType] = useState('ONCE')
    const [partnerLogo, setPartnerLogo] = useState('')
    const [ruleDesc, setRuleDesc] = useState('')
    const [qrCode, setQrCode] = useState('')
    const [currentTime, setCurrentTime] = useState('')
    const [changeCode, setChangeCode] = useState('')
    const [endTime, setEndTime] = useState('')
    const [beginTime, setBeginTime] = useState('')
    const [showTime, setShowTime] = useState(false)
    const [directDesc, setDirectDesc] = useState('')
    const [subTitle, setSubTitle] = useState('')
    

    useEffect(() => {
        // 初始化
        getData()
    }, [])

    const getData = async (id) => {
        const res = await get("promo/get/" + activeCode);
        if (res.code === 0) {
            console.log(res)
            setTitle(res.data.title)
            setPeriodType(res.data.periodType)
            setPartnerLogo(res.data.partnerLogo)
            setRuleDesc(res.data.ruleDesc)
            setQrCode(res.data.qrCode)
            setCurrentTime(res.data.currentTime)
            setEndTime(res.data.endTime)
            setBeginTime(res.data.beginTime)
            setDirectDesc(res.data.directDesc)
            setShowTime(true)
            setSubTitle(res.data.subTitle)
        }
    }
    const getChangeCode =async() => {
        const res = await get("promo/claim/" + activeCode);
        if (res.code === 0) {
            setChangeCode(res.data)
        }
    }
    const getActiveStatus = (status) => {
        console.log(status, '---sss')
    }
    const copyCode = (code, isJump) => {
        if (!changeCode) {
            Toast.show({
                icon: 'fail',
                content: '请生成兑换码',
            })
            return
        }
        // 创建一个新的textarea元素  
        const textarea = document.createElement("textarea");  
        // 设置textarea的内容为需要复制的文本  
        textarea.value = code;  
        // 将textarea添加到文档中  
        document.body.appendChild(textarea);  
        // 选择textarea的文本  
        textarea.select();  
        // 复制选中的文本  
        document.execCommand("copy");  
        // 从文档中移除textarea  
        document.body.removeChild(textarea);  
        Toast.show({
            icon: 'success',
            content: '复制成功',
            afterClose: () => {
                if (isJump) {
                    navigateTo("/my");
                }
            },
        })
    }

    return (
        <>
            {/* <NavBar backArrow={true} onBack={back} style={{ backgroundColor: "#EFEFEF", maxWidth: "650px", margin: "0 auto" }}>
                我的
            </NavBar> */}
            
            <div className='activeBox' id="activeBoxId">
                <div className="imgBg"></div>
                <div className="activeMain">
                    <div className="activeContent">
                        <div className="activeMainTop">
                            <div className="activeLogoLine">
                                <div className="logoOne">
                                    <img src={partnerLogo} alt="" />
                                </div>
                                {/* <div className="logoTwo">
                                    <img src={logoTwoImg} alt="" />
                                </div> */}
                            </div>
                            
                            <div className="activeTitleLine">
                                <div className="activeTitle">
                                    <div>{title}</div>
                                    <div>{subTitle}</div>
                                </div>
                                <div className="activeTitleImg">
                                    <img src={activeTitleImg} alt="" />
                                </div>
                            </div>
                            {
                                showTime?
                                <CountTimeDown getActiveStatus={(status) => {getActiveStatus(status)}} periodType={periodType} startTime={beginTime} endTime={endTime} currentTime={currentTime}></CountTimeDown>
                                : null
                            }
                            
                            <div className="activeCodeLine">
                                <div className="codeTitle">
                                    <span>兑换码：</span>
                                    {changeCode || '--'}
                                    { changeCode ? <FillinOutline style={{marginLeft: '1rem'}} onClick={() => {copyCode(changeCode)}}/> : null}
                                </div>
                                <div className="pdTwo">
                                    <img src={PdSVG} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="activeMainBottom">
                            <div className="activeInt" >
                            <div className="pdThree">
                                    <img src={PdSVG} alt="" />
                                </div>
                                <div className="activeIntBox" dangerouslySetInnerHTML= {{__html: ruleDesc}}></div>
                                {/* <div className="intLine">
                                    <div className="intTitle">
                                        活动目的
                                    </div>
                                    <div className="intcontent">
                                        让更多校友对AI有更深的了解和接触 让AI服务我们的生活
                                    </div>
                                </div>
                                <div className="intLine">
                                    <div className="intTitle">
                                        活动介绍
                                    </div>
                                    <div className="intcontent">
                                        领取校友专属VIP卡，免费畅享所有AI使用1小时仅限校友（根据手机号），每人每月限用3张
                                    </div>
                                </div>
                                <div className="intLine mb0">
                                    <div className="intTitle">
                                        活动时间
                                    </div>
                                    <div className="intcontent">
                                        03.08-03.20，每天上午9:00～10:00开放领取
                                    </div>
                                </div> */}
                                <div className="pdFour">
                                    <img src={PdSVG} alt="" />
                                </div>
                            </div>
                            <div className="QrcodeBox">
                                <div className="outline">
                                    <QRCode
                                        id="payCode"
                                        value={qrCode}
                                        size={200} // 二维码的大小
                                        fgColor="#000000" // 二维码的颜色
                                        style={{ margin: 'auto', width: '16rem', height: '16rem' }}
                                    />
                                </div>
                                    
                            </div>
                            <div className="BtnBox">
                                <div className="btnClass" onClick={() => {getChangeCode()}}>生成兑换码</div>
                                <div className="btnClass" onClick={() => {copyCode(changeCode, true)}}>立即兑换</div>
                            </div>
                        </div>
                        <div className="activeDirectDescBox" dangerouslySetInnerHTML= {{__html: directDesc}}></div>
                    </div>
                    <div className="footerBox">
                        <div className="footerLine">
                            <div className="footerText">
                                本活动由
                                <div className="footerImg">
                                    <img src={FooterLogo} alt="" />
                                </div>
                                提供技术支持
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )


}

export default ActivePage;