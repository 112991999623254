import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button, Form, Input, Toast, Divider, Space } from 'antd-mobile';
import "../css/login.css"
import logo from "../../assert/loginLogo.png";
import { useLocation, useNavigate } from "react-router";
import { get, post } from "../common/Http";
import { EyeInvisibleOutline, EyeOutline } from "antd-mobile-icons";
import {  MessageOutline, SmileOutline } from 'antd-mobile-icons'



function ResetPassword() {

    const navigateTo = useNavigate();
    let timer = null
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [oneCode, setOneCode] = useState("");
    const [loginType, setLoginType] = useState("PASSWORD");
    const [codeSendStep, setCodeSendStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [sendLoading, setSendLoading] = useState(false);
    const [eyeFlag, setEyeFlag] = useState(true);
    const [content, setContent] = useState("");
    const [contentUrl, setContentUrl] = useState("");
    const intervalRef = useRef(null); // 使用useRef来存储计数器的值，并在setInterval函数中访问它
    const [count, setCount] = useState(0); // 初始值count=0
    const location = useLocation();

    const onFinish = (value) => {
        console.log(value)
    }

    const changeLoginType = (type) => {
        setLoginType(type);
    }

    const handleSendCode = async () => {

        if (!phoneNumber) {
            Toast.show("请输入手机号");
            return
        }

        const param = {
            username: phoneNumber
        }

        try {
            const res = await post("u/sms/send", param);
            if (res.code === 0) {
                setCodeSendStep(2);
                Toast.show({
                    content: "发送成功！",
                    duration: 2000,
                    afterClose: () => {

                    }
                })
                setCount(59); // 从59秒开始倒计时
            } else {
                Toast.show(res.errorMsg)
            }
        } catch (e) {
            console.log(e);
        }

    }


    const reset = async () => {

        if (!phoneNumber) {
            Toast.show("请输入手机号");
            return
        }
        if (loginType === "PASSWORD" && !password) {
            Toast.show("请输入密码");
            return
        }

        if (loginType === "VERIFY_CODE" && !oneCode) {
            Toast.show("请输入验证码");
            return
        }

        let param = {
            username: phoneNumber,
            newPassword: password,
            verifyCode:oneCode
        }

        try {
            setLoading(true);
            const res = await post("u/pwd/reset", param);
            if (res.code === 0) {
                localStorage.setItem("auth_pwd", password);
                Toast.show({
                    content: "重置成功！",
                    duration: 2000,
                    afterClose: () => {
                        setLoading(true);
                        // navigateTo("/find");
                        navigateTo(location.state?.from || '/login');
                    }
                })
            } else {
                Toast.show({
                    content: res.errorMsg,
                    duration: 2000,
                    afterClose: () => {
                        setLoading(false);
                        navigateTo("/signup");
                    }
                })
                setLoading(false);
            }

        } catch (e) {
            console.log(e);
            setLoading(false);
        }

    }

    const changeEye = () => {
        setEyeFlag(!eyeFlag);
    }



    useEffect(() => {

        const getData = async () => {

            try {
                const res = await get("u/protocol");
                if (res.code === 0) {
                    setContent(res.data.content);
                    setContentUrl(res.data.fileUrl);
                } else {
                    Toast.show(res.errorMsg)
                }

            } catch (e) {
                console.log(e);
            }
        }
        getData();

        if (localStorage.getItem("auth_pwd")) {
            setPassword(localStorage.getItem("auth_pwd"));
            setPhoneNumber(localStorage.getItem("number_user"));
        }

        if (localStorage.getItem("number_user")) {
            setPhoneNumber(localStorage.getItem("number_user"));
        }
        return () => {
            clearInterval(intervalRef.current);
        }
    }, [])
     // 监听count的变化 
    useEffect(() => {
        if (count === 59) {
        intervalRef.current = setInterval(() => {
            setCount((preCount) => preCount - 1);
        }, 1000);
        } else if (count === 0) {
            clearInterval(intervalRef.current);
        }
    }, [count]);

    return (
        <>
            <div className={"login-container"}>
                <div className={"up-box"}>
                    <div className={"title"}>重置密码<br />帮助您找回账号</div>
                    <div className={"title-logo"}>
                        <img src={logo} width={80} height={30} alt="" />
                    </div>
                </div>
                <div className={"form-area"}>
                    <Form
                        name='form'
                        onFinish={onFinish}>
                        <Form.Item label='手机号'>
                            <Input placeholder='请输入您的手机号' value={phoneNumber} onChange={(val) => {
                                setPhoneNumber(val)
                            }} />
                        </Form.Item>
                        <Form.Item label='验证码'>
                            <div className="codeClass">
                                <Input placeholder='请输入验证码' maxLength={6} onChange={(val) => {
                                    setOneCode(val)
                                }} clearable />
                                {
                                    count ? (
                                        <div className="codeSendClass">{`${count} s后重试`}</div>
                                    ) : <div className="codeSendClass" onClick={handleSendCode}>{`获取验证码`}</div>
                                }
                            </div>
                                
                            </Form.Item>

                       <Form.Item label='新密码' className={"pwd-eye"}>
                            {eyeFlag ? <a href="#" className={"pwd-eye-icon"} onClick={changeEye}><EyeInvisibleOutline
                                fontSize={36} /></a> :
                                <a href="#" className={"pwd-eye-icon"} onClick={changeEye}><EyeOutline
                                    fontSize={36} /></a>}
                            <Input type={eyeFlag ? "password" : "text"} placeholder='请输入密码' value={password}
                                onChange={(val) => {
                                    setPassword(val)
                                }} />
                        </Form.Item>
              
                        
                    </Form>
                </div>

                <div className={"foot-text"}>
                    <a href={contentUrl} target={"_blank"}>{content}</a>
                </div>

                <div className={"btn-determine-sign"}>
                        <Button block color='primary' size='large' shape='rounded' loading={loading} onClick={reset}>
                            <Space>
                                <MessageOutline />
                                <span>确认重置</span>
                            </Space>
                        </Button>

                </div>
                <div className={"btn-determine-sign"}>
                    <Button block fill='outline' color="primary" size='large' shape='rounded' loading={loading}
                        onClick={() => {
                            navigateTo("/login")
                        }}>
                        <Space>
                        <SmileOutline />
                            <span>返回登录</span>
                        </Space>
                    </Button>
                </div>

                <div className={"foot-text"}>
                </div>
            </div>
        </>
    )
}

export default ResetPassword;