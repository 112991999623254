import React, {useEffect, useState} from "react";
import MenuBottom from "../common/component/MenuBottom";
import { NavBar, Card, Toast, Divider, Grid, Ellipsis,Space  } from 'antd-mobile'
import "../css/find.css";
import {CloseOutline,MessageOutline} from "antd-mobile-icons";
import {useLocation,useNavigate} from "react-router";
import {get} from "../common/Http";
import {stripHtml, textFormat} from "../common/tool";

function LikeList() {

    const navigateTo = useNavigate();
    const location = useLocation();

    const [list, setList] = useState([]);

    const back = () =>
        Toast.show({
            content: '点击了返回区域',
            duration: 1000,
        })

    useEffect(()=>{

        const getData = async () => {

            try {
                const res = await get("app/subs/list");
                if(res.code === 0) {
                    setList(res.data);
                } else if(res.code === 9998)  {
                    // Toast.show({
                    //     content: res.errorMsg,
                    //     duration: 2000,
                    //     afterClose: ()=> {
                    //         navigateTo("/login")
                    //     }
                    // })
                    navigateTo('/login', { state: { from: location } });
                } else {
                    Toast.show({
                        content: res.errorMsg,
                        duration: 2000,
                    })
                }
            } catch (e) {
                console.log(e);
            }
        }
        getData();

    },[])

    const pageTo = (item) => {
        navigateTo(`/details?appCode=${item.appCode}`);
    }
       
    const getChatUrl = async (item) => {

        try {
            const res = await get(`app/url/${item.appCode}/false`);
            if (res.code === 0) {
                // setAppDetail(res.data);
                window.location.href = res.data;
            } else if (res.code === 9998) {
                navigateTo("/login" ,{ state: { from: location } });
            } else {
                Toast.show(res.errorMsg)
            }

        } catch (e) {
            console.log(e);
        }
    }
    return (
        <>
            {/* <NavBar backArrow={false} onBack={back} style={{backgroundColor: "#EFEFEF", maxWidth: "650px", margin: "0 auto"}}>
                知新AI
            </NavBar> */}

            <div className={"content-container"} style={{paddingTop: '10px'}}>
                {
                    list.map((item, i) => {

                        return (
                            <div className={"card-cover"} key={i}>
                                <Card className={"adm-card-body"} onClick={()=>pageTo(item)}>
                                    <div className={"content"}>
                                        <Grid columns={5} gap={8}>
                                            <Grid.Item span={1} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                <div className={"left-card-title_img_box"}>
                                                    <img src={item.icon} alt="" width={50}/>
                                                </div>
                                            </Grid.Item>
                                            <Grid.Item span={4}>
                                                <div className={"right-card-title"}>
                                                    <h3>
                                                        {item["appName"]}
                                                    </h3>
                                                    <div>
                                                        <Ellipsis direction='end' rows={2} content={stripHtml(item.appDesc)} />
                                                    </div>
                                                </div>
                                            </Grid.Item>
                                        </Grid>
                           
                                    </div>
                                    <Divider />
                                    <div className={"company-name"}>
                                        <div className="itemFooterL">
                                            <Ellipsis direction='end' rows={1} content={textFormat(item.company || '', 30)} />
                                        </div>
                                        <div className="itemFooterR">
                                            <Space style={{ alignSelf: 'flex-start',fontSize:'12px','--gap':'2px'}} onClick={(e) => {
                                                e.stopPropagation();
                                                getChatUrl(item)}}>
                                            <MessageOutline color='#0066FF' />
                                            开始聊天
                                            </Space>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        )
                    })
                }
            </div>
            <div className={"bottom-tab"}>
                <MenuBottom></MenuBottom>
            </div>

        </>
    )
}

export default LikeList;