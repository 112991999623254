import React, { useState, useEffect } from 'react';
import MenuBottom from "../common/component/MenuBottom";
import {NavBar, Card, Toast, Divider, Grid, Space, Tag, Tabs, Ellipsis} from 'antd-mobile'
import "../css/find.css";
import {MessageOutline} from "antd-mobile-icons";
import {useNavigate,useLocation} from "react-router";
import {post, get} from "../common/Http";
import {textFormat,stripHtml} from "../common/tool";
import styles from '../css/findLess.less';

function Find() {

    const navigateTo = useNavigate();
    const location = useLocation();

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(200);
    const [tagId, setTagId] = useState("");
    const [taglist, setTagList] = useState([]);
    const [subTagList, setSubTagList] = useState([]);

    const [list, setList] = useState([]);


    useEffect(()=>{
        getData();
        getTags();
    },[])

    const getData = async (id) => {

        const param = {
            current: page,
            pageSize: pageSize,
            tagId: id,
        }

        const res = await post("app/v2/list", param);
        if(res.code === 0) {
            setList(res.data.records);
        } else if(res.code === 9998)  {
            Toast.show(res.errorMsg);
            navigateTo("/login");
        } else {
            Toast.show({
                content: res.errorMsg,
                duration: 2000,
            })
        }
    }

    const getTags = async () => {

        const res = await get("app/tags");
        if(res.code === 0) {
            let tagsList = [...res.data];
            let obj = {
                "tagId": 0,
                "tagName": "全部",
                "subTags": []
            };
            tagsList.unshift(obj);
            tagsList.map(item => {
                if(item.tagId === 0) {
                    item.checked = true;
                } else {
                    item.checked = false;
                }
            })
            setTagList(tagsList);
            setTagId(0);
        } else if(res.code === 9998)  {
            Toast.show(res.errorMsg);
            navigateTo("/login");
        } else {
            Toast.show({
                content: res.errorMsg,
                duration: 2000,
            })
        }
    }

    const back = () => {
        navigateTo(-1)
    }

    const changeSubTag = async (tagId) => {
        const subList = taglist.filter((item => item.tagId === tagId));
        let subObj = subList[0];
        if(tagId !== 0) {
            if(subObj.subTags) {
                subObj.subTags.map((item,i) => {
                    if(i === 0) {
                        item.checked = true;
                    } else {
                        item.checked = false;
                    }
                })
                await getData(subObj.subTags[0].tagId);
                setSubTagList(subObj.subTags);
            } else {
                await getData("");
                setSubTagList([]);
            }
        } else {
            await getData("");
            setSubTagList([]);
        }
        setTagId(tagId)
    }

    const handleSubTag = async (tagId) => {
        let subTags = [...subTagList];
        subTags.map(item => {
            if(item.tagId === tagId) {
                item.checked = true;
            } else {
                item.checked = false;
            }
        })
        setSubTagList(subTags);
        await getData(tagId);
    }

    const pageTo = (item) => {
        navigateTo(`/details?appCode=${item.appCode}`);
    }
    const changeTab = () => {
            let eleList = document.getElementsByClassName('adm-tabs-tab')
            console.log(eleList)
            if (eleList && eleList.length > 0) {
                let tagIndex = taglist.findIndex(item => {
                    return item.tagId === tagId
                })
                console.log(tagIndex, '---tagIndex---', (eleList[tagIndex].scrollWidth * 0.8).toFixed(1) + 'px')
                return (eleList[tagIndex].scrollWidth * 0.9).toFixed(1) + 'px'
            } else {
                return '20px'
            }
        
    }

   
    const getChatUrl = async (item) => {

        try {
            const res = await get(`app/url/${item.appCode}/false`);
            if (res.code === 0) {
                // setAppDetail(res.data);
                window.location.href = res.data;
            } else if (res.code === 9998) {
                navigateTo("/login" ,{ state: { from: location } });
            } else {
                Toast.show(res.errorMsg)
            }

        } catch (e) {
            console.log(e);
        }

    }

    return (
        <>
            {/* <NavBar backArrow={false} onBack={back} style={{backgroundColor: "#EFEFEF", maxWidth: "650px", margin: "0 auto"}}>
                知新AI
            </NavBar> */}
            <div className={"parent-tag-container"}>
                <div className={styles.customHeader}>
                    <Tabs className={styles.tabs}
                          activeKey={tagId}
                          activeLineMode="fixed"
                          style={{
                              '--fixed-active-line-width': changeTab(),
                              '--active-line-height': '4px'
                          }}
                          onChange={(key)=>changeSubTag(Number(key))}>
                        {
                            taglist.map(item => {
                                return (
                                    <Tabs.Tab title={item.tagName} key={item.tagId} style={{color: tagId===item.tagId?"#000000":"#A9A9A9",fontWeight: tagId===item.tagId?"bold":""}}>
                                        {item.tagName}
                                    </Tabs.Tab>
                                )
                            })
                        }
                    </Tabs>
                </div>
            </div>
            {
                subTagList.length !== 0 && <div className={"tag-container"}>
                    <div className={"sp-tag-container"}>
                        {
                            subTagList.map(item => {
                                return (
                                    <div key={item.tagId} className={item.checked?"sp-tag-check":"sp-tag"}  onClick={()=>handleSubTag(item.tagId)} >
                                        {`#${item.tagName}`}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            }

            <div className={"content-container"}>
                {
                    list.map((item, i) => {

                        return (
                            <div className={"card-cover"} key={i}>
                                <Card className={"adm-card-body"} onClick={()=>pageTo(item)}>
                                    <div className={"content"}>
                                        <Grid columns={5} gap={8}>
                                            <Grid.Item span={1} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                <div className={"left-card-title_img_box"}>
                                                    <img src={item.icon} alt="" width={50}/>
                                                </div>
                                            </Grid.Item>
                                            <Grid.Item span={4}>
                                                <div className={"right-card-title"}>
                                                    <h3>
                                                        {item["appName"]}
                                                    </h3>
                                                    <div>
                                                        <Ellipsis direction='end' rows={2} content={stripHtml(item.appDesc)} />
                                                    </div>
                                                </div>
                                            </Grid.Item>
                                        </Grid>
                           
                                    </div>
                                    <Divider />
                                    <div className={"company-name"}>
                                        <div className="itemFooterL">
                                            <Ellipsis direction='end' rows={1} content={textFormat(item.company || '', 30)} />
                                        </div>
                                        <div className="itemFooterR">
                                            <Space style={{ alignSelf: 'flex-start',fontSize:'12px','--gap':'2px'}} onClick={(e) => {
                                                e.stopPropagation();
                                                getChatUrl(item)}}>
                                            <MessageOutline color='#0066FF' />
                                            开始聊天
                                            </Space>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        )
                    })
                }
            </div>
            <div className={"bottom-tab"}>
                <MenuBottom></MenuBottom>
            </div>

        </>
    )
}

export default Find;