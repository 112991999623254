import React, { useState, useEffect } from 'react';
import {Button, Grid, Form, Input, Toast} from 'antd-mobile';
import "../css/sign-up.css"
import logoRound from "../../assert/logo-round.png";
import { get, post } from "../common/Http";
import { useNavigate} from "react-router";
import { useLocation } from 'react-router-dom';

const onFinish = (value) => {
    console.log(value)
}

function SignUp() {

    const [btnState, setBtnState] = useState(1);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [oneCode, setOneCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [sendLoading, setSendLoading] = useState(false);
    const [content, setContent] = useState("");
    const [contentUrl, setContentUrl] = useState("");
    const [affCode,setAffCode] = useState("");

    const navigateTo = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const initialValues = {
        affCode: query.get('aff') || '',  // 如果有aff参数则使用，否则为空字符串
      };

    useEffect(()=>{

        setAffCode(query.get('aff'));
        
 

        const getData = async () => {
            try {
                const res = await get("u/protocol");
                if(res.code === 0) {
                    setContent(res.data.content);
                    setContentUrl(res.data.fileUrl);
                } else {
                    Toast.show(res.errorMsg)
                }

            } catch (e) {
                console.log(e);
            }
        }
        getData();

    },[affCode])

    const sendCode = async () => {
        if(!phoneNumber) {
            Toast.show("请输入手机号");
            return
        }

        const param = {
            username: phoneNumber
        }

        try {
            setSendLoading(true);
            const res = await post("u/sms/send", param);
            setBtnState(0)
            console.log(res);
            if(res.code === 0) {
                setSendLoading(false);
                Toast.show({
                    content: "发送成功！",
                    duration: 2000,
                    afterClose: ()=> {

                    }
                })
            } else {
                Toast.show(res.errorMsg)
                setSendLoading(false);
            }

        } catch (e) {
            console.log(e);
            setLoading(true);
        }


    }

    const register = async () => {
        if(!phoneNumber) {
            Toast.show("请输入手机号");
            return
        }
        if(!password) {
            Toast.show("请设置密码");
            return
        }
        if(!oneCode) {
            Toast.show("请输入验证码");
            return
        }

        const param = {
            "username": phoneNumber,
            "password": password,
            "verifyCode": oneCode,
            "affCode":affCode
        }
        try {
            setLoading(true);
            const res = await post("u/register", param);
            if(res.code === 0) {
                Toast.show({
                    content: "注册成功，请登录！",
                    duration: 2000,
                    afterClose: ()=> {
                        setLoading(false);
                        navigateTo("/login");
                    }
                })
            } else {
                setLoading(false);
                Toast.show(res.errorMsg)
            }

        } catch (e) {
            console.log(e);
            setLoading(false);
            Toast.show("注册失败")
        }

    }

    const backToLogin = () => {
        navigateTo("/login")
    }

    return (
        <div className={"container"}>
            <div className={"sign-up-box"}>
                <div className={"sign-title-logo"}>
                    <img src={logoRound} alt="" width={90}/>
                </div>
                <div className={"signup-title"}>
                    您好<br/>欢迎注册知新AI
                </div>
            </div>
            <div className={"sign-up-content"}>
                <div className={"sign-form-area"}>
                    <Form
                        name='form'
                        onFinish={onFinish}
                        initialValues={initialValues}>
                        <Form.Item name='name' label='手机号' className={"top-input"}>
                            <Input placeholder='请输入您的手机号' onChange={(val) => setPhoneNumber(val)}/>
                        </Form.Item>
                        <Form.Item name='password' label='设置密码'>
                            <Input type="password" placeholder='请设置密码' onChange={(val) => setPassword(val)}/>
                        </Form.Item>
                        <Form.Item name='code' label='验证码'>
                            <Input placeholder='请输入验证码' maxLength={6} onChange={(val) => setOneCode(val)}/>
                        </Form.Item>
                        <Form.Item name='affCode' label='推荐码（选填）'>
                            <Input placeholder='注册推荐码' maxLength={20} onChange={(val) => setAffCode(val)}/>
                        </Form.Item>
                    </Form>
                </div>
                <div className={"sign-small-form"}>
                    <div>
                        <a href="#" onClick={backToLogin}>返回登录</a>
                    </div>
                </div>
                <div className={"foot-text"}>
                    <a href={contentUrl} target={"_blank"}>{content}</a>
                </div>
                <div className={"btn-determine btn-determine-sign "}>
                    {
                        btnState ? <Button block color='primary' size='large' shape='rounded' loading={sendLoading}
                                           onClick={sendCode}>
                                发送验证码
                            </Button> :
                            <Button block fill='outline' color="primary" size='large' shape='rounded' loading={loading}
                                    onClick={register}>
                                注册
                            </Button>
                    }
                </div>
            </div>
        </div>
    )
}

export default SignUp;