export const textFormat = (text,len) => {
    let newStr = "";
    if(text.length <= len) {
        newStr = text;
    } else {
        newStr = `${text.slice(0,len)}...`
    }
    return newStr;
}

export const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
}