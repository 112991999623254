import React, {useEffect, useState,useRef} from "react";
import {NavBar, Badge,Card, Toast, Divider, Grid, Space, Avatar, Button,Collapse} from 'antd-mobile';
import QRCode from 'qrcode.react';
import "../css/find.css";
import {useNavigate} from "react-router";
import {get, post} from "../common/Http";
import {LinkOutline, RedoOutline} from "antd-mobile-icons";
import MenuBottom from "../common/component/MenuBottom";

function MyApp() {

    const navigateTo = useNavigate();
    // const avaTest = "https://img.aibotk.com/wechat/avatar/N1lQRVdLM203NEI5ZXdKbA.jpeg";
    // const qrTest = "https://login.weixin.qq.com/l/gZI40WKrxQ==";

    const [avatar, setAvatar] = useState("");
    const [qrUrl, setQrUrl] = useState("");
    const [qrStatus, setQrStatus] = useState(4);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [botName, setBotName] = useState("");
    const [bots,setBots] = useState([]);
    const timerID = useRef(null);

    const updateItem = (itemId, newValue) => {
        const updatedItems = bots.map((item,index) => {
          if (index === itemId) {
            return { ...item, ...newValue };
          }
          return item;
        });
        setBots(updatedItems);
      };


    const back = () => {
        navigateTo(-1)
    }

    useEffect(()=>{
        // localStorage.setItem("Auth_Token","AK_65b7370be4b03f5b8605f5f7");
        getData();
    },[])

    useEffect(() => {
        return () => {
            stopRefresh();
        };
      }, []);

    // 启动定时器的方法
    const startRefresh = (bots) => {
      // 确保在启动新定时器之前，没有已经运行的定时器
      if (timerID.current === null) {
        timerID.current = setInterval(() => {
            Promise.all(bots.map((b,index) => refresh(index,b.configId)))
              .then(refreshBots => {
                setBots(refreshBots);
              });
          }, 5000);
        console.log('定时器启动');
      } else {
        console.log('定时器已经在运行中');
      }
    };
  
    // 停止定时器的方法
    const stopRefresh = () => {
      if (timerID.current !== null) {
        clearInterval(timerID.current);
        timerID.current = null; // 清除定时器ID的引用
        console.log('定时器停止');
      } else {
        console.log('没有运行中的定时器可以停止');
      }
    };

    const getData = async (id) => {

        try {
            const res = await get("b/chat-bot/stat");
            if(res.code === 0) {
                if(res.data) {
                    setBots(res.data);
                    if(timerID.current === null){
                        startRefresh(res.data);
                    } 
                }
            } else if(res.code === 9998)  {
                Toast.show(res.errorMsg);
                navigateTo("/login");
            } else {
                Toast.show({
                    content: res.errorMsg,
                    duration: 2000,
                })
            }
        } catch (e) {
            console.log(e);
        }
    }

    const reSetUp = async (configId) => {
        setLoading2(true);
        try {
            const res = await get("b/chat-bot/restart/" + configId);
            if(res.code === 0) {
                setLoading2(false);
                Toast.show({
                    content: "重启成功！",
                    duration: 2000,
                })
            } else if(res.code === 9998)  {
                setLoading2(false);
                Toast.show(res.errorMsg);
                navigateTo("/login");
            } else {
                Toast.show({
                    content: res.errorMsg,
                    duration: 2000,
                })
            }
        } catch (e) {
            console.log(e);
            setLoading2(false);
        }
    }

    const reload = async (index,configId) => {
        setLoading1(true);
        try {
            const res = await get("b/chat-bot/refresh/" + configId);
            if(res.code === 0) {
                if(res.data) {                    // let obj = res.data[0];
                    updateItem(index,res.data);
                    setLoading1(false);
                    Toast.show({
                        content: "刷新成功！",
                        duration: 2000,
                    })
                }
            } else if(res.code === 9998)  {
                setLoading1(false);
                Toast.show(res.errorMsg);
                navigateTo("/login");
            } else {
                Toast.show({
                    content: res.errorMsg,
                    duration: 2000,
                })
            }
        } catch (e) {
            console.log(e);
            setLoading1(false);
        }
    }

    //自动刷新
    const refresh = async (index,configId) => {
        try {
            const res = await get("b/chat-bot/refresh/" + configId);
            if(res.code === 0) {
                if(res.data) {
                    // updateItem(index, res.data);
                    return res.data;
                }
            } else if(res.code === 9998)  {
                setLoading1(false);
                Toast.show(res.errorMsg);
                navigateTo("/login");
            } else {
                Toast.show({
                    content: res.errorMsg,
                    duration: 2000,
                })
            }
        } catch (e) {
            console.log(e);
            setLoading1(false);
        }
    }

    return (
        <>
            <NavBar backArrow={true} onBack={back} style={{backgroundColor: "#EFEFEF", maxWidth: "650px", margin: "0 auto"}}>
                我的应用
            </NavBar>
    
            <div className={'detail-container'} >
            <Collapse accordion defaultActiveKey='0'>
            { bots.map((bot,index) => (
            <Collapse.Panel key={index} title={bot.botName} style={{fontWeight:"bold"}}>
            {bot.qrStatus === 4 ? <Badge
            color='#87d068'
            content='已连接'
            bordered='true'>
                <div className={'ava-area'}>
                    <Space block wrap  style={{ display: 'flex',justifyContent: 'center'}}>
                        <Avatar src={bot.avatar} style={{ '--size': '96px'}} />
                    </Space>
                    <Space block wrap style={{marginTop: "15px",display: 'flex',justifyContent: 'center'}}>
                        <h2 style={{color:'black'}}>{bot.botName}</h2>
                    </Space>

                    <Space block wrap style={{ display: 'flex',justifyContent: 'center'}}>
                   <h2 style={{fontSize:"13px"}}>更新时间：</h2>
                   </Space>

                   <Space block wrap style={{ display: 'flex',justifyContent: 'center'}}>
                   <h2 style={{fontSize:"13px"}}>{bot.updatedAt}</h2>
                   </Space>
                </div>
                </Badge>
            :  <Badge
            content='已离线'
            bordered='true'>
                <div className={'ava-area'}>
                    <Space block wrap>
                        <Avatar src={bot.avatar} style={{ '--size': '96px'}} />
                    </Space>
                    <Space block wrap style={{marginTop: "15px"}}>
                        <h2>{bot.botName}</h2>
                    </Space>
                </div>
                </Badge>   
            }
                {
                    bot.qrStatus !== 4 && (
                        <div className={'qr-area'}>
                            <QRCode
                                value={bot.qrUrl}
                                size={200} // 二维码的大小
                                level={"H"} // 错误更正水平：'L', 'M', 'Q', 'H'
                                bgColor={"#ffffff"} // 背景颜色
                                fgColor={"#000000"} // 前景颜色
                            />
                            <div style={{marginTop: "10px"}}>
                                <h2 style={{textAlign: "center"}}>扫码登录</h2>
                                <h2 style={{textAlign: "center"}}>请用客服微信号扫码</h2>
                                <h2></h2>
                            </div>
                        </div>
                    )
                }

                <div className={'btn-area'}>
                    <Space wrap>
                        <Button loading={loading1} style={{marginRight: "20px"}} onClick={() => reload(index,bot.configId)}>
                            <Space>
                                <RedoOutline />
                                <span>刷新</span>
                            </Space>
                        </Button>
                        <Button loading={loading2} onClick={() => reSetUp(bot.configId)}>
                            <Space>
                                <LinkOutline />
                                <span>重启</span>
                            </Space>
                        </Button>
                    </Space>
                </div>
        
                   </Collapse.Panel>
                ))}
                  </Collapse>
                  <div className={'bot-hint-div'}>
                <h2 style={{fontSize:16,textAlign: 'center'}}>温馨提示</h2>
                                <h2 >如显示二维码，说明AI已掉线
                                </h2>
                                <h2>1.点击“重启”，等待重启成功
                                </h2>
                                <h2 >2.稍等几秒，点击“刷新”
                                </h2>
                                <h2 >3.新二维码出现后，用客服微信扫码登录即可
                                </h2>
                
            </div>
            </div>


       
         
            <div className={"bottom-tab"}>
                <MenuBottom></MenuBottom>
            </div>
        </>

    )


}

export default MyApp;