import React, {useState, useEffect} from 'react';
import {
    Form,
    Input,
    Button,
    Mask,
    TextArea,
    Toast} from 'antd-mobile'
import "../css/createOrder.css";
import {useLocation, useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";
import {post, get} from "../common/Http";
import MenuBottom from "../common/component/MenuBottom";

function CreateOrder() {

    const navigateTo = useNavigate();

    const location = useLocation();
    const [searchParams] = useSearchParams();

    const [orderId, setOrderId] = useState('1')
    const [count, setCount]  =useState("1")
    const [memo, setMemo] = useState('')
    const [visible, setVisible] = useState(false)
    const [testValue, setTestValue] = useState('')
    const [val, setVal] = useState(1)
    const [payParams, setPayParams] = useState(null)
    useEffect(()=>{
        if (payParams) {
            // 检测支付环境中的 WeixinJSBridge
            if (typeof WeixinJSBridge == "undefined") {
                if (document.addEventListener) {
                    document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                } else if (document.attachEvent) {
                    document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                    document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                }
            } else {
                onBridgeReady();
            }
        }
    },[ payParams ])
    const onFinish=() => {
        console.log('开始提交')
        setVisible(true)
        createdOrder()
    }
    const createdOrder = async() => {
        const param = {
            "comId": orderId,
            "quantity": count,
            "memo": memo
        }
        const res = await post("/order/test/payment", param);
        setVisible(false)
        setTestValue(JSON.stringify(res))
        if (res.code === 0) {
            console.log(res)
            setPayParams(res.data.prepayParam)
        } else if (res.code === 9998) {
            navigateTo("/login");
        } else {
            Toast.show(res.errorMsg)
        }
    }
    const onBridgeReady = () => {
        WeixinJSBridge.invoke(
            'getBrandWCPayRequest', {
                "appId": payParams.appId, //公众号名称，由商户传入     
                "timeStamp": payParams.timeStamp, //时间戳，自1970年以来的秒数     
                "nonceStr": payParams.nonceStr, //随机串     
                "package": payParams.packageVal,
                "signType": payParams.signType, //微信签名方式：     
                "paySign": payParams.paySign //微信签名 
            },
            function(res) {
                // 支付成功
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                }
                // 支付过程中用户取消
                if (res.err_msg == "get_brand_wcpay_request:cancel") {
    
                }
                // 支付失败
                if (res.err_msg == "get_brand_wcpay_request:fail") {
    
                }
                /**
                 * 其它
                 * 1、请检查预支付会话标识prepay_id是否已失效
                 * 2、请求的appid与下单接口的appid是否一致
                 * */
                if (res.err_msg == "调用支付JSAPI缺少参数：total_fee") {
    
                }
            });
    }

    return (
        <>
            <div className="order-container">
                <div className={"detail-body"}>
                {testValue}
                <Form
                    layout='horizontal'
                    onFinish={onFinish}
                    footer={
                    <Button block type='submit' color='primary' size='large'>
                        下单
                    </Button>
                    }
                >
                    {/* <Form.Header>水平布局表单</Form.Header> */}
                    <Form.Item
                        name='orderId'
                        label='商品ID'
                        rules={[{ required: true, message: '请输入商品ID' }]}
                    >
                        <Input 
                            value={orderId}
                            onChange={val => {
                                setOrderId(val)
                            }}
                            placeholder='请输入商品ID' />
                    </Form.Item>
                    <Form.Item
                        name='count'
                        label='商品数量'
                        rules={[{ required: true, message: '请输入商品数量' }]}
                    >
                        <Input 
                            value={count}
                            onChange={val => {
                                setCount(val)
                            }}
                            placeholder='请输入商品数量' />
                    </Form.Item>
                    <Form.Item name='memo' label='备注'>
                        <TextArea
                            placeholder='请输入备注'
                            maxLength={100}
                            rows={2}
                            value={memo}
                            onChange={val => {
                                setMemo(val)
                            }}
                        />
                    </Form.Item>
                </Form>
                </div>
            </div>
            <Mask visible={visible}>
                <div>加载中...</div>
            </Mask>
            <div className={"bottom-tab"}>
                <MenuBottom></MenuBottom>
            </div>
        </>
    )
}

export default CreateOrder;