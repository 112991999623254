import React from 'react'
import { NavBar, TabBar } from 'antd-mobile'
import {
    Route,
    Switch,
    useNavigate,
    useLocation
} from 'react-router-dom'
import {
    AppOutline,
    MessageOutline,
    UnorderedListOutline,
    UserOutline,
} from 'antd-mobile-icons'

const MenuBottom = () => {

    const navigateTo = useNavigate();
    const location = useLocation();
    const { pathname } = location;

    const setRouteActive = (value) => {
        navigateTo(value)
    }

    const tabs = [
        {
            key: '/find',
            title: '发现',
            icon: <AppOutline  />,
        },
        {
            key: '/like',
            title: '关注',
            icon: <UnorderedListOutline  />,
        },
        {
            key: '/my',
            title: '我的',
            icon: <UserOutline  />,
        }
    ]

    return (
        <TabBar activeKey={pathname} onChange={value => setRouteActive(value)} style={{backgroundColor: "#fff"}}>
            {tabs.map(item => (
                <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
            ))}
        </TabBar>
    )


}

export default MenuBottom;