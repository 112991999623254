import React, { useEffect, useState } from "react";
import { NavBar, Card, Toast, Grid, Dialog, Input, Ellipsis, Button, Space, Modal } from 'antd-mobile'
import "../css/profile.css";
import { useNavigate } from "react-router";
import MenuBottom from "../common/component/MenuBottom";
import dayjs from 'dayjs'
import { get, post } from "../common/Http";
import bgtImg from "../../assert/bgt.jpg";
import bgbImg from "../../assert/bgb.jpg";
import ktImg from "../../assert/kt.jpg";
import dhImg from "../../assert/dh.jpg";
import vipIcon from "../../assert/vip.svg";
import { RightOutline } from "antd-mobile-icons";




function My() {

    const navigateTo = useNavigate();

    const back = () => {
        navigateTo(-1)
    }

    const [avatar, setAvatar] = useState("");
    const [nickName, setNickName] = useState("");
    const [registerDate, setRegisterDate] = useState("");
    const [userCode, setUserCode] = useState("");
    const [username, setUsername] = useState("");
    const [appCount, setAppCount] = useState(0);
    const [vipInfo, setVipInfo] = useState(null);
    const [isAppDisabled, setIsAppDisabled] = useState(false);
    const [redeemCode, setRedeemCode] = useState('')
    const [modalVisible, setModalVisible] = useState(false)
    const [vipHistory, setVipHistory] = useState([])
    const [affUrl, setAffUrl] = useState('')
    const [activeCardNum, setActiveCardNum] = useState(0)
    const [inActiveCardNum, setInActiveCardNum] = useState(0)
    const [expiredCardNum, setExpiredCardNum] = useState(0)

    useEffect(() => {
        getData();
        getStatus();
        getAff();
        getActiveList()
    }, [])

    const disabledStyle = isAppDisabled
        ? { opacity: 0.5, pointerEvents: 'none' }
        : {};

    const getData = async (id) => {

        const res = await get("u/mine");

        if (res.code === 0) {
            if (res.data) {
                setAvatar(res.data.avatar);
                setNickName(res.data.nickName);
                setRegisterDate(res.data.registerDate);
                setUserCode(res.data.userCode);
                setUsername(res.data.username);
                if (res.data.vipInfo && res.data.vipInfo.length > 0) {
                    setVipInfo(res.data.vipInfo[0])
                } else {
                    setVipInfo(null)
                }
            }
        } else if (res.code === 9998) {
            Toast.show(res.errorMsg);
            navigateTo("/login");
        } else {
            Toast.show({
                content: res.errorMsg,
                duration: 2000,
            })
        }
    }
    const openVip = () => {
        navigateTo("/vipList");
    }
    const openMyCard = () => {
        navigateTo("/myCard")
    }
    // 获取我的兑换记录
    const recordsList = () => {
        get("u/redeem/history").then(res => {
            if (res.code === 0) {
                setVipHistory(res.data)
            }
        })
    }
    // 兑换提交
    const redeemHandle = (code) => {
        return get("u/redeem/" + code).then(res => {
            return res
        })
    }
    // 获取专属链接
    const getAff = () => {
        get("/u/aff").then(res => {
            if (res.code === 0) {
                setAffUrl(res.data)
            }
        })
    }

    //复制专属链接
    const copyHandle = (text) => { 
        // 创建一个新的textarea元素  
        const textarea = document.createElement("textarea");  
        // 设置textarea的内容为需要复制的文本  
        textarea.value = affUrl;  
        // 将textarea添加到文档中  
        document.body.appendChild(textarea);  
        // 选择textarea的文本  
        textarea.select();  
        // 复制选中的文本  
        document.execCommand("copy");  
        // 从文档中移除textarea  
        document.body.removeChild(textarea);  
        Toast.show({
            icon: 'success',
            content: '复制成功'
        })
    }
    const getActiveList = () => {
        get("/u/packs").then(res => {
            if (res.code === 0) {
                const activeList = (res.data || []).filter(item => {
                    return item.vipStatus === 'ACTIVE'
                })
                setActiveCardNum(activeList.length)
                const inActiveList = (res.data || []).filter(item => {
                    return item.vipStatus === 'INACTIVE'
                })
                setInActiveCardNum(inActiveList.length)
                const expiredList = (res.data || []).filter(item => {
                    return item.vipStatus !== 'INACTIVE' && item.vipStatus !== 'ACTIVE'
                })
                setExpiredCardNum(expiredList.length)
            }
        })
    }
        

    const getStatus = async () => {
        const res = await get("b/chat-bot/stat");
        if (res.code === 0) {
            if (res.data) {
                setAppCount(res.data.length);
            }
        } else if (res.code === 9998) {
            Toast.show(res.errorMsg);
            navigateTo("/login");
        } else if (res.code === 9) {
            setIsAppDisabled(true);
        } else {
            Toast.show({
                content: res.errorMsg,
                duration: 2000,
            })
        }

    }
    const changeVisible = async () => {
        await recordsList()
        setRedeemCode('')
        setModalVisible(true)
    }

    const logout = async () => {
        const res = await get("u/logout");
        if (res.code === 0) {
            navigateTo('/find');
        } else if (res.code === 9998) {
            Toast.show(res.errorMsg);
            navigateTo("/login");
        } else {
            Toast.show({
                content: res.errorMsg,
                duration: 2000,
            })
        }
    }
    const jumpOrder = () => {
        navigateTo("/orderList");
    }
    const jumpSharing = () => {
        navigateTo("/sharing");
    }


    return (
        <>
            {/* <NavBar backArrow={true} onBack={back} style={{ backgroundColor: "#EFEFEF", maxWidth: "650px", margin: "0 auto" }}>
                我的
            </NavBar> */}
            <div className='myBox'>
                <div className="backGround">
                    <img className="topBg" src={bgbImg} alt="" />
                    {/* <img className="bottomBg" src={bgtImg} alt="" /> */}
                </div>
                <div className="contentBox">
                    <div className="userBox">
                        <div className="info">
                            <div className="infoImg">
                                <img src={avatar} alt="" />
                            </div>
                            <div className="infoNameBox">
                                <div className="name">{nickName}</div>
                                <div className="id">{userCode.slice(userCode.length-6, userCode.length) }</div>
                            </div>
                        </div>
                        {
                                vipInfo ? (
                                    <div className="vipBox">
                                        <div className="icon">
                                            <img src={vipIcon} alt="" />
                                        </div>
                                        <div className="vipTime">{dayjs(vipInfo.expiredAt).format("YYYY-MM-DD")}</div>
                                    </div>
                            ) : null
                        }
                        
                    </div>
                    <div className="vipLine">
                        <div className="lineItem" onClick={() => {openVip()}}>
                            <div className="lineImg">
                                <img src={ktImg} alt="" />
                            </div>
                            <div className="lineText">
                                <div className="title">开通会员</div>
                                <div className="tip">限时优惠</div>
                            </div>
                        </div>
                        <div className="lineItem" onClick={() => {changeVisible()}}>
                        <div className="lineImg">
                                <img src={dhImg} alt="" />
                            </div>
                            <div className="lineText">
                                <div className="title">兑换会员</div>
                                <div className="tip">限时优惠</div>
                            </div>
                        </div>
                    </div>
                    <div className="blockBox">
                        <div className="blockItem">
                            <div className="blockTitle">我的卡包</div>
                            <div className="blockClear" onClick={() => {openMyCard()}}>
                                <div className="activeLine">
                                    <div className="activeTitle">生效中</div>
                                    <div className="activeNum">{activeCardNum}</div>
                                </div>
                                <div className="activeLine">
                                    <div className="activeTitle">已过期</div>
                                    <div className="activeNum">{expiredCardNum}</div>
                                </div>
                                
                                <div className="activeLine">
                                    <div className="activeTitle">待激活</div>
                                    <div className="activeNum">{inActiveCardNum}</div>
                                </div>
                            </div>
                        </div>
                        <div className="blockItem">
                            <div className="blockTitle">管理后台</div>
                            <div className="blockSubs">
                                <div className="subItem" onClick={() => navigateTo("/my-app")}>
                                    <div className="subTitle">应用</div>
                                    <div className="subCount">
                                        {appCount}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listClass">
                        <div className="listItemClass">
                            <div className="copyTitle">复制专属链接邀请好友</div>
                            <div className="copyBtn" onClick={() => {copyHandle()}}>复制</div>
                        </div>
                        <div className="listItemClass" onClick={() => {jumpOrder()}}>
                            <div className="copyTitle">我的订单</div>
                            <div className="copyBtn" >
                                <RightOutline color="#8A8A8A"/>
                            </div>
                        </div>
                        <div className="listItemClass" onClick={() => {jumpSharing()}}>
                            <div className="copyTitle">我的分享</div>
                            <div className="copyBtn" >
                                <RightOutline color="#8A8A8A"/>
                            </div>
                        </div>
                    </div>
                    <div className="listClass height10">
                        <span className="loginoutBtn" onClick={() => {
                            Dialog.show({
                                content: '确认退出当前账号吗 ?',
                                closeOnAction: true,
                                actions: [
                                [
                                    {
                                    key: 'cancel',
                                    text: '取消',
                                    style: {
                                        color: '#ddd'
                                    },
                                    },
                                    {
                                    key: 'confirm',
                                    text: '确认',
                                    onClick: () => {
                                        console.log(999)
                                            logout()
                                        },
                                    },
                                ],
                                ],
                            })
                        }}>退出登录</span>
                    </div>
                </div>
            </div>

            <Dialog
                visible={modalVisible}
                closeOnAction
                title="兑换会员"
                content={
                    (
                        <>
                            <div className="dialogInputClass">
                                <span className="dialogTitle">兑换码：</span>
                                <Input placeholder='请输入兑换码' value={redeemCode} onChange={(value) => {
                                    setRedeemCode(value)
                                }} />
                            </div>
                            {vipHistory.length > 0 ? (

                                <div className="dialogTable">
                                    <div className="dialogTableHead">
                                        <div className="one">VIP</div>
                                        <div className="two">兑换时间</div>
                                        <div className="three">到期时间</div>
                                    </div>
                                    <div className="dialogTableBody">
                                        {
                                            vipHistory.map((item, index) => {
                                                return (
                                                    <div className="tableTr" key={index}>
                                                        <div className="one">
                                                            <Ellipsis direction='end' content={item.vipInfoName} />
                                                        </div>
                                                        <div className="two">{dayjs(item.createdAt).format('YYYY-MM-DD')}</div>
                                                        <div className="three">{dayjs(item.expiredAt).format('YYYY-MM-DD')}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            ) : null}

                        </>
                    )
                }
                actions={[[
                    {
                        key: 'close',
                        text: '取消',
                        onClick: () => {
                            setModalVisible(false)
                            setVipHistory([])
                            setRedeemCode('')
                        },
                    },
                    {
                        key: 'confirm',
                        text: '兑换',
                        onClick: async () => {
                            if (!redeemCode) {
                                Toast.show({
                                    icon: 'fail',
                                    content: '请输入兑换码'
                                })
                                return
                            }
                            const res = await redeemHandle(redeemCode)
                            console.log(res, '----')
                            if (res.code === 0) {
                                Toast.show({
                                    icon: 'success',
                                    content: '兑换成功'
                                })
                                getActiveList()
                                setModalVisible(false)
                                setVipHistory([])
                                setRedeemCode('')
                                getData();
                                getStatus();
                                getAff()
                            } else {
                                setModalVisible(false)
                                setVipHistory([])
                                setRedeemCode('')
                            }
                        },
                    }
                ]]}
            >
            </Dialog>

            <div className={"bottom-tab"}>
                <MenuBottom></MenuBottom>
            </div>
        </>

    )


}

export default My;